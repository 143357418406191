import React from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import { twitterContainerStyle } from './styles'

type TServer = {
  [key: string]: string
}

const server: TServer = {
  'arkservers.io': 'arkserversio',
  'blue fang solutions, llc': 'bluefangservers',
  'host havoc ltd.': 'hosthavoc',
  'low.ms': 'lowmshosting',
  'nitrous networks': 'nitrousnetworks',
  pingperfect: 'pingperfect',
  serverblend: 'serverblend',
  shockbyte: 'shockbyte',
}

type TwitterContainerProps = {
  serverName: string
}

const TwitterContainer: React.FC<TwitterContainerProps> = ({ serverName }) => {
  const serverNameInTwitter = serverName.toLowerCase()
  let serverNameLookup = server[serverNameInTwitter] as string

  if (!serverNameLookup) return null

  return (
    <section css={twitterContainerStyle} className="twitterContainer">
      <div className="twitter-embed">
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName={serverNameLookup}
          options={{ theme: 'dark', tweetLimit: 5 }}
          noHeader
          noFooter
          noBorders
        />
      </div>
    </section>
  )
}

export default TwitterContainer
