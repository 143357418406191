/** @jsxImportSource @emotion/react */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { navBarLinkStyle } from '../styles'
import { serverPanel } from './styles'

const ServerPanel = () => {
  return (
    <React.Fragment>
      <ul css={serverPanel} className="server-panel">
        <NavLink css={navBarLinkStyle} to="/servers/all/all">
          Game Servers
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/dedicated-gaming-servers/all">
          Dedicated Game Servers
        </NavLink>
      </ul>
    </React.Fragment>
  )
}

export default ServerPanel
