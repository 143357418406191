import { css } from '@emotion/react'

const backDropStyling = css`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
`

export { backDropStyling }
