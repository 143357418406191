/** @jsxImportSource @emotion/react */
import React from 'react'
import { NavLink } from 'react-router-dom'
import UseMetaTags from '../meta-tags'
import { monthYear } from '../month-year'
import PictureSet from '../helpers/picture-set'
import uniqueGames from './uniqueGames'
import {
  homePageStyle,
  gameList,
  imageGameBanner,
  gameImage,
  startLinkStyle,
  imageContainer,
} from './styles'

const shuffle = (array: string[]) => {
  var currentIndex = array.length || 0,
    temporaryValue,
    randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

const recentlyAddedGamesList = ['palworld', 'enshrouded', 'satisfactory']
const recentlyAddedGamesArray = uniqueGames.filter((game: string) =>
  recentlyAddedGamesList.includes(game),
)

const Home = () => {
  const imagePath = 'images/bannerImages'
  const date = monthYear(new Date())
  return (
    <React.Fragment>
      <UseMetaTags
        content={`Game server hosts offering dedicated game servers ${date}`}
        title={`Search Game Server hosts offering games ${date}`}
        ogDescription={`Hosts offering dedciated game servers ${date}`}
        ogTitle={`Game server hosts offering dedicated game servers ${date}`}
        type="website"
        ogUrl="https://comparegamerserverhosting"
        ogImageUrl="https://comparegameserverhosting/images/ogImages/logoso/normal/all.png"
      />
      <div css={homePageStyle}>
        <h1>Welcome to HOSTFILTER</h1>
        <p>
          Pick an online game to see a list of hosts offering dedicated game
          servers for that game.
        </p>
        <p>Recently added games</p>
        <ul css={gameList}>
          {recentlyAddedGamesArray.map((game: string) => {
            return (
              <li key={game}>
                <a href={`/servers/all/${game}`}>
                  <picture css={imageGameBanner}>
                    <PictureSet imagePath={imagePath} imageName={game} />
                    <img
                      loading="lazy"
                      // src={`/${imagePath}/png/small/${game}.png`}
                      alt={game}
                      css={gameImage}
                    />
                  </picture>
                </a>
              </li>
            )
          })}
        </ul>
        <NavLink css={startLinkStyle} to="/servers/all/all">
          All Games
        </NavLink>
        <div css={imageContainer} className="image-container">
          {shuffle(uniqueGames).map((game, index) => {
            if (index < 12) {
              return (
                <a key={game} href={`/servers/all/${game}`}>
                  <picture css={imageGameBanner}>
                    <PictureSet imagePath={imagePath} imageName={game} />
                    <img
                      loading="lazy"
                      // src={`/${imagePath}/png/small/${game}.png`}
                      alt={game}
                      css={gameImage}
                    />
                  </picture>
                </a>
              )
            }
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
