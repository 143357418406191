import {
  imageDirection,
  readMoreLink,
  rssContent,
  rssItem,
  rssLink,
} from '../../styles'

type ItemContentProps = {
  item: any
  index: number
}

const ItemContent: React.FC<ItemContentProps> = ({ item, index }) => {
  const content = item.content
    .substring(0, 400)
    .replace(/\n ?\n+\t?/gm, '\n\n')
    .replace(/\t/g, '\n')
  return (
    <div css={rssItem(index)} className="rss-item">
      <a css={rssLink} className="rss-link" href={item.link}>
        <div className="rss-link-content" css={rssContent}>
          <h2>{item.title}</h2>
          <h3>{item.pubDate}</h3>
        </div>
        <div
          className="content"
          css={{
            display: 'flex',
            alignItems: 'space-between',
            flexDirection: 'column',
          }}
        >
          <div css={{ objectFit: 'cover' }}>
            <img
              loading="lazy"
              css={imageDirection(index)}
              alt={item.title}
              src={item.enclosure.url}
            />
            <p css={{ whiteSpace: 'pre-wrap' }}>{content}... </p>
          </div>
          <div css={readMoreLink}>
            <a href={item.url}>Click to Read More</a>
          </div>
        </div>
      </a>
    </div>
  )
}

export default ItemContent
