import { css } from '@emotion/react'
import { smallScreen } from '../styles/global'

export const imageStyle = css`
  height: 40px;
  width: 40px;
  ${smallScreen} {
    width: 50%;
  }
`

export const imageLogosStyle = css`
  color: white;
  font-weight: bolder;
`
