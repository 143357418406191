import { css } from '@emotion/react'
import { mediumScreen, smallScreen } from '../styles/global'

const displayGrid = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`

const flagBannerImageStyling = css`
  width: 50px;
  height: 50px;
  padding: 1em;
  margin-left: 1%;
  margin-right: 1%;

  ${mediumScreen} {
    width: 50px;
    height: 50px;
    padding: 0.3em;
  }

  ${smallScreen} {
    width: 50px;
    height: 50px;
    padding: none;
  }
`

const notInList = css`
  opacity: 0.2;
  cursor: default;
`

const bannerImagesHoverStyle = css`
  transition: all 0.2s ease-in-out;
  transform: scale(1.25);

  &:hover {
    transform: scale(1.4);
    z-index: 2;
    opacity: 1;
  }
`

const inactiveBannerImagesHoverStyle = css`
  transition: all 0.2s ease-in-out;
  transform: scale(1.25);

  &:hover {
    transform: scale(1.4);
    z-index: 1;
    opacity: 1;
  }
`

export {
  displayGrid,
  flagBannerImageStyling,
  notInList,
  bannerImagesHoverStyle,
  inactiveBannerImagesHoverStyle,
}
