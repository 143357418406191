export enum CacheKeys {
  HOME = 'ssr:home',
  DEDICATED = 'ssr:dedicated',
  ABOUT_US = 'ssr:about-us',
  SYSTEM_REQUIREMENTS = 'ssr:system-requirements',
  COUPONS = 'ssr:coupons',
  CONTACT_US = 'ssr:contact-us',
  HOST_AREA = 'ssr:host-area',
  ROADMAP = 'ssr:roadmap',
  PRIVACY = 'ssr:privacy',
  GUIDE_ASE = 'ssr:guide:ark-survival-evolved',
  GUIDE_7DTD = 'ssr:guide:7-days-to-die',
  GUIDE_RUST = 'ssr:guide:rust',
  NEWS_ARK = 'ssr:news:ark-news',
  NEWS_STEAM = 'ssr:news:steam-news',
  SERVERS_GAMES = 'ssr:servers:games',
  FEATURE = 'ssr:featured-games',
  UNKNOWN = 'ssr:unknown',
}