import { readMoreLink, rssContent, rssItem, rssLink } from '../../styles'

type SteamContentProps = {
  item: any
  index: number
}

const SteamContent: React.FC<SteamContentProps> = ({ item, index }) => {
  const content = item['content:encodedSnippet']
    .substring(0, 400)
    .replace(/\n ?\n+\t?/gm, '\n\n')
    .replace(/\t/g, '\n')
  return (
    <div css={rssItem(index)} className="rss-item">
      <div className="rss-link-content" css={rssContent}>
        <a css={rssLink} className="rss-link" href={item.link}>
          <h2>{item.title}</h2>
          <p>{item.categories.join(', ')}</p>
          <h3>{item.pubDate}</h3>
          <div
            className="content"
            css={{
              display: 'flex',
              alignItems: 'space-between',
              flexDirection: 'column',
            }}
          ></div>
          <div css={{ objectFit: 'cover' }}>
            <p css={{ whiteSpace: 'pre-wrap' }}>{content}</p>
          </div>
        </a>
        <div css={readMoreLink}>
          <a href={item.url}>Click to read More</a>
        </div>
      </div>
    </div>
  )
}

export default SteamContent
