/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import { rssItemsContainer, rssContainer } from '../styles'
import ItemContent from './item-content'

type TItems = {
  title: string
  categories: string[]
  pubDate: string
  url: string
  'content:encodedSnippet': string
}

type TFeed = {
  title: string
  description: string
  items: TItems[]
}

const RssFeed = () => {
  const [data, setData] = useState<{
    isLoading: boolean
    error: Error | null
    feed: TFeed | null
  }>({
    isLoading: true,
    error: null,
    feed: null,
  })

  async function fetchData() {
    setData({ isLoading: true, error: null, feed: null })
    try {
      const response = await fetch(`/api/ark-news`)
      if (!response.ok) {
        throw new Error(`Network response was not ok (${response.statusText})`)
      }
      const feed = await response.json()
      setData({ isLoading: false, error: null, feed })
    } catch (error) {
      if (error instanceof Error) {
        setData({ isLoading: false, error, feed: null })
        return
      }
      const unknownError = new Error('An unknown error occurred')
      setData({ isLoading: false, error: unknownError, feed: null })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (data.isLoading) {
    return <div>Loading...</div>
  }

  if (data.error) {
    return <div>Error: {data.error.message}</div>
  }

  if (!data.feed || !data.feed.items || data.feed.items.length === 0) {
    return <div>No data</div>
  }

  return (
    <div className="rss-container" css={rssContainer}>
      <h1 css={{ textAlign: 'center', color: 'white' }}>{data.feed.title}</h1>
      <h2 css={{ textAlign: 'center', color: 'white' }}>
        {data.feed.description}
      </h2>
      <div className="rssItemsContainer" css={rssItemsContainer}>
        {data.feed.items &&
          data.feed.items.map((item, index) => (
            <ItemContent key={index} item={item} index={index} />
          ))}
      </div>
    </div>
  )
}

export default RssFeed
