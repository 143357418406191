import { css } from '@emotion/react'

export const displayGrid = (itemCount: number) => css`
  display: grid;
  grid-template-columns: repeat(${itemCount}, 1fr);
  gap: 10px;
  align-items: center;
`

export const platformImageBaseStyle = css`
  margin-left: 1%;
  margin-right: 1%;
  width: 50px;
  height: 50px;
  object-fit: contain;
`

export const platformImageCurrentStyle = css`
  transform: scale(1.25);
  opacity: 1;
`

export const platformImageOtherStyle = css`
  transform: scale(1);
  opacity: 0.2;
`

export const platformImageAllStyle = css`
  opacity: 1;
`

export const bannerImagesHoverStyle = css`
  &:hover {
    transform: scale(1.1);
    opacity: 0.8;
  }
`

export const currentPlatformClass = `${platformImageBaseStyle} ${platformImageCurrentStyle} ${bannerImagesHoverStyle}`
export const otherPlatformClass = `${platformImageBaseStyle} ${platformImageOtherStyle} ${bannerImagesHoverStyle}`
export const allPlatformClass = `${platformImageBaseStyle} ${platformImageAllStyle} ${bannerImagesHoverStyle}`
