import { css } from '@emotion/react'

const whiteArrowStyle = css`
  color: white;
`

const redArrowStyle = css`
  color: red;
`
export { whiteArrowStyle, redArrowStyle }
