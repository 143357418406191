import { css } from '@emotion/react'
import { notTinyScreen } from '../styles/global'

const tinyDisplay = css`
  ${notTinyScreen} {
    flex-direction: column;
  }
`

const gameListImageStyle = css`
  transition: all 0.2s ease-in-out;
  width: 150px;
  height: 82px;
  padding: 0.5em;
`

const imageInfo = css`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.5em;
  padding: 1em;
`

export { tinyDisplay, gameListImageStyle, imageInfo }
