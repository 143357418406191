import { css } from "@emotion/react"

export const centerStyle = css`
  text-align: center;
`

export const buttonStyle = css`
  background-color: blue;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  padding: 0.5em;
  transition-property: background-color;
  transition-duration: 0.6s;
  &:hover {
    background-color: purple;
  }
`
