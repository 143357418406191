
/** @jsxImportSource @emotion/react */
import { toggleButtonLine, toggleButton } from './styles'

type DrawerToggleButtonProps = {
  click: () => void
  drawer: string
}

const DrawerToggleButton: React.FC<DrawerToggleButtonProps> = (props) => {
  return (
    <button
      css={toggleButton}
      aria-label="show-menu"
      onClick={props.click}
      className="toggle-button"
    >
      <div css={toggleButtonLine} className="toggle-button_line"></div>
      <div css={toggleButtonLine} className="toggle-button_line"></div>
      <div css={toggleButtonLine} className="toggle-button_line"></div>
    </button>
  )
}

export default DrawerToggleButton
