import { css } from '@emotion/react'

const guideTable = css`
  color: white;
  background-color: blue;
  text-align: center;
`

const guideStyle = css`
  text-align: center;
  ul {
    list-style: none;
    padding: 0;
  }
`

const gameGuideStyle = css`
  background-color: #1f1f1f;
  box-sizing: border-box;
  padding: 1em;
  width: 80%;

  & .image-container {
    text-align: center;
  }

  & .image-container img {
    max-width: 100%;
  }

  margin: 0 auto;

  & a:visited,
  & a:link {
    color: white;
  }

  & a:visited {
    color: white;
  }

  & a:hover {
    color: pink;
  }

  code {
    background-color: grey;
    padding: 0.2em;
    border-radius: 2px;
  }

  & h2 {
    margin-top: 1em;
    text-align: center;
    color: white;
  }

  .content {
    color: white;

    @media (min-width: 768px) {
      column-count: 3;
    }

    & p {
    }
  }
`

export { guideTable, guideStyle, gameGuideStyle }
