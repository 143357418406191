import { css } from '@emotion/react';
import { smallScreen } from '../styles/global'; // adjust the import path as necessary

const buyFromLinks = css`
  button {
    width: 100%;
  }
  ${smallScreen} {
    display: flex;
    min-height: 6em;
  }
`;

const noSmall = css`
  ${smallScreen} {
    display: none;
  }
`;

const tpClassStyle = css`
  width: 1.5em;
  height: 1.5em;
  font-size: 1em;
  background-color: red;
  border-radius: 50%;
  color: white;
  cursor: help;
`;

const mainImageLogo = css`
  grid-area: mainImageLogo;
  align-self: center;
  ${smallScreen} {
    width: auto;
    display: block;
    a {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 2rem;
    }
  }
`;

const trustPilotArea = css`
  grid-area: trustpilotArea;
  font-size: 0.75em;
  display: flex;
`;

const gridArea = css`
  grid-area: platforms;
`;

const controlPanel = css`
  grid-area: controlPanel;
`;

const dateEstablished = css`
  grid-area: established;
`;

const trustPilotAreaContent = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: inherit;
`;

const establishedStyles = css`
  position: absolute;
  top: 40%;
  left: 31%;
  transform: rotate(5deg);
  font-weight: bolder;
  font-size: 0.35em;
`;

export {
  buyFromLinks,
  noSmall,
  tpClassStyle,
  mainImageLogo,
  trustPilotArea,
  gridArea,
  controlPanel,
  dateEstablished,
  trustPilotAreaContent,
  establishedStyles,
};
