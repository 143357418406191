import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY_REACT || 'YOUR_API_KEY',
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: process.env.BUGSNAG_ENV || 'development', // Set the environment
})

const plugin = Bugsnag.getPlugin('react')
if (!plugin) {
  throw new Error('Bugsnag React plugin not found')
}

const ErrorBoundary = plugin.createErrorBoundary(React)

export { Bugsnag, ErrorBoundary }
