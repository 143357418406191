/** @jsxImportSource @emotion/react */
import React from 'react'
import SortArrows from '../../sort-arrows'
import { useTranslation } from 'react-i18next'
import { mobileOnlyStyle } from '../../styles/global'

import {
  pointer,
  mobileSort,
  serverBlockStyle,
  singleServerBlockTitleStyle,
} from '../../styles/titles'
import { onlySmall } from './styles'
import TooltipHolder from '../../tooltip-holder'
import { SortEnum } from '../../grid'

type TitlesProps = {
  sortByHosts: () => void
  sortByReviews: () => void
  sortByRating: () => void
  sortByCGSH: () => void
  sortByEstablished: () => void
  currentSort: SortEnum
  sorts: {
    hostSort: boolean
    ratingSort: boolean
    reviewsSort: boolean
    CGSHSort: boolean
    establishedSort: boolean
  }
}

export const Titles: React.FC<TitlesProps> = (props) => {
  const { t } = useTranslation()
  const {
    sortByHosts,
    sortByReviews,
    sortByRating,
    sortByCGSH,
    sortByEstablished,
    currentSort,
    sorts,
  } = props
  return (
    <React.Fragment>
      <div css={serverBlockStyle} className={`server-block titles-block`}>
        <div
          css={singleServerBlockTitleStyle}
          className="single-server-block-title"
        >
          <div className="main-image-logo">
            <TooltipHolder title={t('titles.hosting-company')}>
              <div css={pointer} onClick={sortByHosts}>
                <SortArrows
                  title={t('titles.hosting-company')}
                  sortBy={SortEnum.hosts}
                  currentSort={currentSort}
                  sort={sorts.hostSort}
                />
              </div>
            </TooltipHolder>
          </div>
          <div className="trustpilot-area">
            <div css={pointer} onClick={sortByRating}>
              <SortArrows
                title={t('titles.trustpilot-rating')}
                sortBy={SortEnum.overall_rating}
                currentSort={currentSort}
                sort={sorts.ratingSort}
              />
            </div>
            <div css={pointer} onClick={sortByReviews}>
              <SortArrows
                currentSort={currentSort}
                title={t('titles.total-reviews')}
                sortBy={SortEnum.review_count}
                sort={sorts.reviewsSort}
              />
            </div>
            <div css={pointer} onClick={sortByCGSH}>
              <SortArrows
                currentSort={currentSort}
                title={t('titles.our-score')}
                sortBy={SortEnum.cgsh}
                sort={sorts.CGSHSort}
              />
            </div>
          </div>
          <div className="locations">
            <p>{t('titles.locales')}</p>
          </div>
          <div className="platforms">
            <p>{t('titles.platforms')}</p>
          </div>
          <div className="control-panel">
            <p>{t('titles.control-panel')}</p>
          </div>
          <div className="support">
            <p>{t('titles.features')}</p>
          </div>
          <div
            className="established"
            css={pointer}
            onClick={sortByEstablished}
          >
            <SortArrows
              title={t('titles.year-founded')}
              sortBy={SortEnum.established}
              currentSort={currentSort}
              sort={sorts.establishedSort}
            />
          </div>
        </div>
      </div>
      <div id="site-sorts" css={onlySmall}>
        <div css={mobileSort} onClick={sortByHosts}>
          <SortArrows
            title={t('titles.hosting-company')}
            sortBy={SortEnum.hosts}
            currentSort={currentSort}
            sort={sorts.hostSort}
          />
        </div>
        <div css={mobileSort} onClick={sortByRating}>
          <SortArrows
            title={t('titles.trustpilot-rating')}
            sortBy={SortEnum.overall_rating}
            currentSort={currentSort}
            sort={sorts.ratingSort}
          />
        </div>
        <div css={[mobileSort, mobileOnlyStyle]}>Buy</div>
      </div>
    </React.Fragment>
  )
}

export default Titles
