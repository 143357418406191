import { css, keyframes } from '@emotion/react'
import {
  smallScreen,
  notTinyScreen,
  mediumScreen,
  largeScreen,
} from '../styles/global' // adjust the import path as necessary

export const showAllGamesButtonStyle = css`
  padding: 0.5em 0;
  width: 100%;
  color: white;
  background-color: darkblue;
`

export const instructionContainerStyle = css`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 6em;

  ${smallScreen} {
    width: 90%;
  }
`

export const guideLinkStyle = css`
  width: fit-content;
  margin: 0.5em auto 0;
  background-color: #1f1f1f;
  border-radius: 3px;
  padding: 0.5em;
  color: white;

  a,
  a:visited,
  a:link {
    text-decoration: none;
  }
`

export const dualInstruction = css`
  display: flex;

  ${notTinyScreen} {
    flex-direction: column;
  }

  ${smallScreen} {
    width: 100%;
  }
`

export const iframeVideo = css`
  display: flex;
  justify-content: center;
  padding: 0.5em;
  align-items: center;
  background-color: #0a062e;
  margin: 0.3em 0 0.3em 0.3em;
  border: 3px solid grey;

  ${smallScreen} {
    margin: 0 0 0 0.1em;
  }

  ${notTinyScreen} {
    margin: 0.1em 0 0 0;
  }
`

export const instructionsStyle = css`
  display: flex;
  flex-direction: row;
  background-color: #0a062e;
  color: white;
  font-size: 0.6em;
  border: 3px solid grey;
  margin-bottom: 0.2em;
  padding-right: 0.5em;
  grid-template-columns: 1fr 9fr;
  margin: 0.5em auto;
  box-sizing: border-box;

  ${mediumScreen} {
    flex-direction: column;
    align-items: center;
  }

  ${smallScreen} {
    margin: 0 auto;
    width: -webkit-fill-available;
  }
`

export const instructionsStyleBanners = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #0a062e;
  color: white;
  font-size: 0.6em;
  border: 3px solid grey;
  margin-bottom: 0.2em;
  padding-bottom: 0.5em;
  padding-right: 0.5em;
  margin: 0.5em auto;
  box-sizing: border-box;
`

export const instructionsStyle2 = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #0a062e;
  color: white;
  font-size: 0.6em;
  border: 3px solid grey;
  margin-bottom: 0.2em;
  padding-right: 0.5em;
  margin: 0.5em auto;
  box-sizing: border-box;

  ${smallScreen} {
    display: none;
  }
`

export const largerFlex = css`
  flex: 2 1 auto;
  padding: 1em;

  ${mediumScreen} {
    flex-direction: column;
  }
`

export const gameSynopsisStyle = css`
  h1 {
    color: white;
  }
  p {
    color: #c8c8c8;
  }
  .game-desc {
    grid-area: desc;
    columns: 2;
  }
  .iframe-wrapper {
    height: 100%;
    text-align: center;
  }
  .game-title {
    grid-area: title;
    text-align: center;
  }
  .guide {
    grid-area: guide;
    align-self: center;
    justify-self: center;
    background-color: red;
    padding: 1em;
    color: white;
    border-radius: 40%;
    transition:
      background-color 1s ease,
      color 1s ease;
    &:hover {
      background-color: pink;
    }
  }
  & a {
    text-decoration: none;
    color: white;
  }
  & a:visited {
    color: white;
  }
  & a:link {
    color: white;
  }

  ${largeScreen} {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas: 'title title guide' 'desc desc video';
    grid-gap: 1em;
    .game-desc {
      columns: 2;
      text-align: left;
    }
  }
  @media only screen and (min-width: 1500px) {
    width: 80%;
    box-sizing: border-box;
  }
  padding: 0.5em 1em 1em 1em;
  margin-bottom: 1em;
  border-radius: 1em;
`

export const gameTitleStyle = css`
  grid-area: title;
  font-size: 1.5em;
`

export const gameDescStyle = css`
  grid-area: desc;
  column-count: 3;
`

export const imageCallToAction = css`
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.1em;
  width: 80%;
  font-size: 2em;
  color: white;
  background-color: #ec48f0;
  &:hover {
    background-color: red;
  }
`
