import ReactGA from 'react-ga4'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export enum AnalyticsType {
  EVENT = 'event',
  PAGEVIEW = 'pageview',
}

export type ExtraData = {
  sendType?: AnalyticsType
  category?: string
  action?: string
  label?: string
  [key: string]: any
}

const useAnalytics = (
  extraData: ExtraData | string = { sendType: AnalyticsType.PAGEVIEW },
) => {
  const location = useLocation()

  // Memoize extraData to avoid unnecessary re-renders
  const memoizedExtraData = useMemo(() => {
    // Ensure extraData is an object
    if (typeof extraData !== 'object' || extraData === null) {
      return { sendType: AnalyticsType.PAGEVIEW }
    }
    return extraData
  }, [JSON.stringify(extraData)])

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.info('Analytics event', {
        page: location.pathname,
        ...memoizedExtraData,
      })
    }

    if (typeof window !== 'undefined' && window.GA_INITIALIZED) {
      if (memoizedExtraData.sendType === AnalyticsType.PAGEVIEW) {
        ReactGA.send({
          hitType: 'pageview',
          page: location.pathname,
          ...memoizedExtraData,
        })
      } else if (memoizedExtraData.sendType === AnalyticsType.EVENT) {
        const { sendType, category, action, label, ...eventData } =
          memoizedExtraData as ExtraData
        if (category && action) {
          ReactGA.event({
            category,
            action,
            label,
            ...eventData,
          })
        } else {
          console.error('Category and action are required for GA events')
        }
      }
    }
  }, [location.pathname, memoizedExtraData])
}

export default useAnalytics
