/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { notTinyScreen } from '../styles/global'

const amazonTextContainer = css`
  display: flex;
  padding-right: 0.5em;
  flex-direction: column;
`

const amazonLinks = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
  background-color: #f68700;
  box-sizing: border-box;
  padding: 0.5em;
  color: #0f1111;
`

const whiteLink = css`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2em;
  color: inherit;
`

const amazonLinkContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 3px;
  background-color: #f3a847;
  ${notTinyScreen} {
    display: none;
  }
`

const amazonLinkContainerFirst = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  border: 1px solid white;
  border-radius: 3px;
  background-color: #f3a847;
`

const amazonImages = css`
  width: 100px;
  height: 100px;
`

export {
  amazonTextContainer,
  amazonLinks,
  whiteLink,
  amazonLinkContainer,
  amazonLinkContainerFirst,
  amazonImages,
}
