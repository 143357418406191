/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
// import SingleReview from '../../singleReview/index.js'
import Stars from '../../stars'
import ImageLogo from '../../image-logo'
import SupportLogo from '../../support-logo'
import { NavLink } from 'react-router-dom'
import FlagLogo from '../../flag-logo'
import { useTranslation } from 'react-i18next'
import { fadeIn, fadeOut } from '../../styles/transitions'
import { imageStyle } from '../../styles/hostRow'

import {
  mobileOnlyStyle,
  // tooltipStyle,
} from '../../styles/global'
import {
  couponContainer,
  couponBar,
  tpClassStyle,
  establishedStyles,
} from './styles'
import {
  countryFlagsStyle,
  extraServicesBarStyle,
  extraServicesStyle,
  hostLink,
  hoverTip,
  linkButtons,
  pStyle,
  singleServerDediBlock,
  singleServerDediListStyle,
  supportingBarStyle,
  supportLogosStyle,
  trustPStyle,
} from '../../styles/hostRow'
import useAnalytics from '../../hooks/useAnalytics'
import useAffiliateClick from '../../hooks/useAffiliateClickButton'

const exceptions = ['LOW.MS']

const exception = (serverName: string) =>
  exceptions.some((server_name) => server_name !== serverName)

// todo extract this to a types file
export type DediServerDataType = {
  established: number
  extras: string
  dedi_locations: string
  modified_url: string
  overall_rating: string
  review_count: number
  server_name: string
  support: string
  trust_pilot_url: string
  url: string
  affiliate_link: string
  coupons: string
  coupon_expiry: string
  coupon_description: string
  name: string
  price: string
  server_nice_name: string
  ips: string
  extra_ips: boolean
  port_speed: string
  data_limit: string
  hdds: string
  cpus: string
  memory: string
  ram_min: string
  ram_max: string
}

type HostRowProps = {
  handleHostChange: any
  server: DediServerDataType
  currentHost: string
  currentCountry: string
  aOrB: boolean
  hostName: string
}

const HostRow: React.FC<HostRowProps> = (props) => {
  const { t } = useTranslation()
  const handleAffiliateClick = useAffiliateClick()

  useAnalytics({
    category: 'Dedicated Server',
    action: `Viewed ${props.server.server_name}`,
  })

  let { server, currentHost, currentCountry, aOrB } = props
  const {
    established,
    extras,
    dedi_locations,
    modified_url,
    overall_rating,
    review_count,
    server_name,
    support,
    trust_pilot_url,
    url,
    affiliate_link,
    coupons,
    coupon_expiry,
    coupon_description,
    server_nice_name,
    price,
    ips,
    extra_ips,
    port_speed,
    data_limit,
    hdds,
    cpus,
    memory,
    ram_min,
    ram_max,
  } = server

  const date = new Date()

  const overall_rating_number = parseFloat(overall_rating)
  const cgshScore = Math.round(overall_rating_number * review_count)
  const runningYears = date.getFullYear() + 1900 - established || 1
  const cgshAvg = Math.round(cgshScore / runningYears)

  /* extract */
  const serverLocations = dedi_locations
    .split(',')
    .map((location) => location.split(':')[0].trim())
  const hasLocation =
    serverLocations.includes(currentCountry) || currentCountry === 'all'
  const hasHost = currentHost === 'all' || server_name === currentHost
  /* extract */

  const shouldShow = hasLocation && hasHost
  const show = shouldShow ? fadeIn : fadeOut
  const display = !shouldShow ? { display: 'none' } : {}

  const removeEnds = /[.][\d\w.]+/

  const hostName = server_name !== currentHost ? server_name : 'all'

  const fadeColors = keyframes`
  0% {
    color:green;
    background-color: blue;
  }

  50% {
    color:blue;
    background-color: red;
  }

  100% {
   color: green;
   background-color: blue 
  }
`

  const animation = css`
    animation: ${fadeColors} 10s ease infinite;
  `

  const Coupons = () => {
    const expiryDate = new Date(coupon_expiry.split('T')[0])
    return expiryDate.getTime() > Date.now() ? (
      <div className="coupon-box" css={[animation, couponBar]}>
        {' '}
        <div className="coupon-container" css={couponContainer}>
          <span>
            <span css={mobileOnlyStyle}>Description: {coupon_description}</span>
          </span>
          <span>
            <span css={mobileOnlyStyle}>Coupon: {coupons}</span>
          </span>
        </div>
      </div>
    ) : null
  }

  return (
    <div
      css={[show, singleServerDediListStyle]}
      key={server_nice_name}
      className="server-list"
    >
      <div css={singleServerDediBlock} className="single-server-block">
        <div
          css={css`
            grid-area: mainImageLogo;
            align-self: center;
            cursor: default;
          `}
          className="main-image-logo"
        >
          <ImageLogo
            serverName={server_name}
            imageName={modified_url}
            css={hoverTip}
          />
          <span className="desktop-title" style={{ color: 'white' }}>
            {exception(server_name)
              ? server_name.replace(removeEnds, '')
              : server_name}
          </span>
        </div>
        <div
          css={css`
            grid-area: trustpilotArea;
          `}
          className="trustpilot-area"
        >
          <a className="trustpilot-area-content" href={trust_pilot_url}>
            <p className="big-p" css={trustPStyle}>
              {t('titles.rating')}: {overall_rating_number.toFixed(1)}
            </p>
            <Stars stars={overall_rating_number} />
            <p css={pStyle}>
              {t('titles.total-reviews')}: {review_count}
            </p>
          </a>
          <p css={pStyle}>
            {t('titles.our-score')}: {cgshScore}
            <span>
              &nbsp;
              <span css={tpClassStyle}>&nbsp;?&nbsp;</span>
            </span>
          </p>
          <p css={pStyle}>
            {t('titles.our-score-yearly-average')}: {cgshAvg}
            <span>
              &nbsp;
              <span css={tpClassStyle}>&nbsp;?&nbsp;</span>
            </span>
          </p>
        </div>
        <div
          css={css`
            grid-area: priceIcon;
            color: yellow;
          `}
          className="price-icon"
        >
          {Array(parseInt(price))
            .fill('£')
            .reduce((acc, item) => acc + item, '')}
        </div>
        <div
          css={css`
            grid-area: locations;
          `}
          className="locations"
        >
          <div css={countryFlagsStyle} className="country-flags">
            {dedi_locations.split(',').map((locale) => {
              const objectLocale = locale.trim().split(':')
              return (
                <FlagLogo
                  key={objectLocale[0]}
                  locale={objectLocale[0].trim()}
                />
              )
            })}
          </div>
        </div>
        <div
          css={css`
            grid-area: memoryGroup;
          `}
          className="memory-group"
        >
          {memory}
          <br />
          {ram_min}GB - {ram_max}GB
        </div>
        <div
          css={css`
            grid-area: dediCpus;
          `}
          className="dedi-cpus"
        >
          {cpus}
        </div>
        <div
          css={css`
            grid-area: dediHdds;
          `}
          className="dedi-hdds"
        >
          {hdds}
        </div>
        <div
          css={css`
            grid-area: ipGroup;
          `}
          className="ip-group"
        >
          {ips}
          <br />
          {extra_ips ? 'Yes' : 'No'}
          <br />
          {port_speed}Gb
          <br />
          {data_limit}
        </div>
        <div
          css={css`
            grid-area: support;
          `}
          className="support"
        >
          <div css={supportLogosStyle}>
            {support.length > 0 &&
              support
                .trim()
                .split(',')
                .map((support) => {
                  return (
                    <SupportLogo
                      key={support.trim()}
                      imageName={support.trim()}
                    />
                  )
                })}
          </div>
        </div>
        <div
          css={css`
            grid-area: established;
          `}
          className="established"
        >
          <div
            css={css`
              position: relative;
              text-align: center;
              color: white;
            `}
            className="container"
          >
            <img
              loading="lazy"
              src={`/images/established/normal/png/established.png`}
              alt="established"
            />
            <div
              css={[establishedStyles, imageStyle(established.toString())]}
              className="centered"
            >
              EST. {established}
            </div>
          </div>
        </div>
        <div css={linkButtons} className="link-buttons">
          <div data-value={hostName} onClick={props.handleHostChange}>
            <NavLink css={hostLink} to={`/dedicated/${hostName}`}>
              {t('titles.host-details')}
            </NavLink>
          </div>
          <div css={{ button: { width: '100%' } }}>
            <button
              css={[hostLink, { backgroundColor: 'purple' }]}
              onClick={() =>
                handleAffiliateClick(server_name, url, affiliate_link)
              }
            >
              <span>
                {t('titles.buy-from')} {server_nice_name}
              </span>
              {process.env.NODE_ENV !== 'production' && affiliate_link}
            </button>
          </div>
        </div>
      </div>
      <div css={[display, supportingBarStyle]} className="supporting">
        <span css={extraServicesBarStyle} className="extra-services-bar">
          {t('titles.special-features')}
        </span>
        <span css={extraServicesStyle} className="extra-services">
          {extras}
        </span>
      </div>
      <Coupons />
    </div>
  )
}

export default HostRow
