/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { pageText, pageContainer, pageTitle } from '../styles/admin'
import { buttonStyle, centerStyle } from './styles'
import useAnalytics from '../hooks/useAnalytics'

type UnknownProps = {
  staticContext: any
}

const Unknown: React.FC<UnknownProps> = ({ staticContext = {} }) => {
  staticContext.notFound = true
  const location = useLocation()
  const { t } = useTranslation()

  useAnalytics({
    page: 'the unknown page',
    location: location.pathname,
  })

  return (
    <div css={pageContainer} className="add-host-page-container">
      <h1 css={pageTitle} className="add-host-page-title">
        {t('introductions.unknown-title')}
      </h1>
      <div css={[centerStyle, pageText]} className="add-host-page-text">
        <p>{t('introductions.unknown-text')}</p>
        <NavLink css={buttonStyle} to={'/'}>
          {t('titles.homepage')}
        </NavLink>
      </div>
    </div>
  )
}

export default Unknown
