import { css } from '@emotion/react'
import { smallScreen } from './styles/global'

export const versionNumberStyle = css`
  color: white;
  margin-right: 1em;
  text-align: right;
  background-color: #1f1f1f;
  width: 100%;
  justify-self: flex-end;
  font-weight: normal;
  font-size: 0.7vw;
  ${smallScreen} {
    font-size: 1em;
  }
`
