import { css } from '@emotion/react'

const navBarLinkStyle = css`
  color: white;
  padding: 0.5em 1em;
`

const sideDrawerStyling = css`
  height: auto;
  background: #3f3f3f;
  position: fixed;
  top: 4rem;
  left: 0;
  max-width: 400px;
  z-index: 200;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  a {
    text-align: center;
    color: white;
    text-decoration: none;
    font-size: 3vh;
    width: auto;
    &:hover,
    &:active {
      background-color: grey;
    }
    &:active {
      background-color: #222222;
      color: #cccccc;
    }
  }
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
`

const adminPanel = css`
  background: #cccccc;
  text-align: center;
  a:nth-of-type(odd) {
    background-color: lightgrey;
  }
  a:nth-of-type(even) {
    background-color: #555555;
  }
`

const open = css`
  transform: translateX(0);
  overflow-y: scroll;
`

export { navBarLinkStyle, sideDrawerStyling, adminPanel, open }
