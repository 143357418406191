/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { navBarLinkStyle } from '../styles'

const guidePanel = css`
  position: fixed;
  display: flex;
  z-index: 3;
  flex-direction: column;
  background-color: darkgrey;
`

const GuideMenu = () => {
  return (
    <Fragment>
      <ul css={guidePanel} className="guide-panel">
        <NavLink css={navBarLinkStyle} to="/guides/rust">
          Rust Guide
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/guides/7-days-to-die">
          7 Days Guide
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/guides/ark-survival-evolved">
          Ark Guide
        </NavLink>
      </ul>
    </Fragment>
  )
}

export default GuideMenu
