type AmazonLinkData = {
  href: string
  text: string[]
  description: string
  imageName: string
}

const amazonLinksData: AmazonLinkData[] = [
  {
    href: 'https://www.amazon.co.uk/s?k=laptops+gaming&crid=39CRIQCTW1CDW&sprefix=laptops+gam%2Caps%2C375&linkCode=ll2&tag=kristiansigst-21&linkId=1f140230da5ef9a9e095de1e428ffe35&language=en_GB&ref_=as_li_ss_tl',
    text: ['Gaming', 'Laptops'],
    description: 'laptops for gaming',
    imageName: 'amazon-gaming-laptop-computer',
  },
  {
    href: 'https://www.amazon.co.uk/s?k=great+gaming+desktops&amp;crid=2YB6CXCZDQB0S&amp;sprefix=great+gaming+deskt%252Caps%252C417&amp;ref=nb_sb_noss&_encoding=UTF8&tag=kristiansigst-21&linkCode=ur2&linkId=ae52006c2d29ec76b67e38668ff35865&camp=1634&creative=6738',
    text: ['Gaming', 'Desktops'],
    description: 'desktops for gaming',
    imageName: 'amazon-gaming-desktop-computer',
  },
  {
    href: 'https://www.amazon.co.uk/s?k=great+gaming+monitors&amp;crid=2DY3LFQIMS3DD&amp;sprefix=great+gaming+moni%252Caps%252C517&amp;ref=nb_sb_noss_2&_encoding=UTF8&tag=kristiansigst-21&linkCode=ur2&linkId=55b2edc348bd58444c7811c9ea2a56ec&camp=1634&creative=6738',
    text: ['Gaming', 'Monitors'],
    description: 'monitors for gaming',
    imageName: 'amazon-gaming-monitor',
  },
  {
    href: 'https://www.amazon.co.uk/s?k=great+gaming+chairs&_encoding=UTF8&tag=kristiansigst-21&linkCode=ur2&linkId=185c4f5662c95484c224c45e74656284&camp=1634&creative=6738',
    text: ['Gaming', 'Chairs'],
    description: 'chair for gaming',
    imageName: 'amazon-gaming-chair',
  },
  {
    href: 'https://www.amazon.co.uk/s?k=great+gaming+mice&amp;ref=nb_sb_noss&_encoding=UTF8&tag=kristiansigst-21&linkCode=ur2&linkId=3c25446b7d95dc81fda91a494f51b3a5&camp=1634&creative=6738',
    text: ['Gaming', 'Mice'],
    description: 'mice for gaming',
    imageName: 'amazon-gaming-mice',
  },
  {
    href: 'https://www.amazon.co.uk/s?k=great+gaming+desks&amp;ref=nb_sb_noss&_encoding=UTF8&tag=kristiansigst-21&linkCode=ur2&linkId=3c25446b7d95dc81fda91a494f51b3a5&camp=1634&creative=6738',
    text: ['Gaming', 'Desks'],
    description: 'desk for gaming',
    imageName: 'amazon-gaming-desk',
  },
]

export default amazonLinksData