import { css } from '@emotion/react'
import { largeScreen, mediumScreen, smallScreen } from '../styles/global'

const backgroundColorDefault = '#1f1f1f'

const linkStyle = css`
  text-decoration: none;
  color: white;
`

const navBarStyle = css`
  ${linkStyle};
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 5fr;
  margin: 0 auto;
  width: 80%;
  ${smallScreen} {
    font-size: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0.5em 0;
  }
`

const siteLogoStyle = css`
  ${linkStyle};
  justify-self: flex-start;
  ${smallScreen} {
    text-align: center;
  }
`

const navBarLinksStyle = css`
  position: relative;
  z-index: 200;
  a {
    position: relative;
  }
  ${largeScreen} {
    font-size: 0.5em;
    align-items: center;
  }
  display: flex;
  justify-content: space-between;
  ${smallScreen} {
    margin-top: 0.5em;
    font-size: 1em;
    display: none; /* changed by the hamburger menu icon */
    flex-direction: column;
    text-align: left;
  }
`

const navBarLinkStyle = css`
  ${linkStyle};
  ${largeScreen} {
    &:hover {
      background-color: #3e3e3e;
    }
    padding: 1em;
  }
  ${smallScreen} {
    margin: 0.1em;
    border: 1px solid white;
    padding: 0.5em;
    width: 100%;
    background-color: #3e3e3e;
  }
`

const versionNumberStyle = css`
  color: white;
  margin-right: 1em;
  text-align: right;
  background-color: #1f1f1f;
  width: 100%;
  justify-self: flex-end;
  font-weight: normal;
  font-size: 0.7vw;
  ${smallScreen} {
    font-size: 1em;
  }
`

const toolbar = css`
  top: 0;
  left: 0;
  z-index: 4;
  position: fixed;
  width: 100%;
  background-color: ${backgroundColorDefault};
  height: 75px;
`

const toolbarNavigation = css`
  display: flex;
  height: 100%;
  align-items: center;
`

const toolbarNavigationItems = css`
  display: flex;
  align-items: center;
  height: 100%;

  a {
    &:hover {
      background-color: #3e3e3e;
    }
    height: 100%;
    color: white;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-content: center;

    li {
      height: 100%;

      ${mediumScreen} {
        padding: 0 0.5rem;
      }

      a {
        height: 100%;
      }
    }
  }
`

const linky = css`
  height: 100%;
`

const toolbarLogo = css`
  margin-left: 1rem;

  a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
  }
`

const spacer = css`
  flex: 1;
`

export {
  toolbar,
  toolbarNavigation,
  toolbarNavigationItems,
  linky,
  toolbarLogo,
  spacer,
  backgroundColorDefault,
  linkStyle,
  navBarLinksStyle,
  navBarLinkStyle,
  versionNumberStyle,
  siteLogoStyle,
  navBarStyle,
}
