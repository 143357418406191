/** @jsxImportSource @emotion/react */
import React from 'react'
import { whiteArrowStyle, redArrowStyle } from './styles'
import { SerializedStyles } from '@emotion/react'
import { SortEnum } from '../grid'

type SortArrowsProps = {
  title: string
  currentSort: SortEnum
  sortBy: SortEnum
  sort: boolean
}

const SortArrows: React.FC<SortArrowsProps> = ({
  title,
  currentSort,
  sortBy,
  sort,
}) => {
  const getArrowStyle = (isUp: boolean): SerializedStyles => {
    if (currentSort !== sortBy) return whiteArrowStyle
    return isUp
      ? sort
        ? whiteArrowStyle
        : redArrowStyle
      : !sort
        ? whiteArrowStyle
        : redArrowStyle
  }

  return (
    <React.Fragment>
      <span css={getArrowStyle(true)}>&#x25B2;&nbsp;</span>
      <span>{title}</span>
      <span css={getArrowStyle(false)}>&nbsp;&#x25BC;</span>
    </React.Fragment>
  )
}

export default SortArrows
