/** @jsxImportSource @emotion/react */
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  footerLink,
  logo,
  adminButtonLink,
  adminLinks,
  adminLinks2,
  footerStyle,
  footerColumnContainer,
} from './styles'

const Bottom = () => {
  const { t } = useTranslation()
  const resetStorage = () => {
    if (__isBrowser__) {
      localStorage.removeItem('consent')
      localStorage.removeItem('answered')
      window.location.reload()
    }
  }
  return (
    <footer>
      <div className="footer-style" css={footerStyle}>
        <div className="column-container" css={footerColumnContainer}>
          <div
            css={{
              gridArea: 'column1',
              backgroundColor: '#1f1f1f',
            }}
          >
            <NavLink
              css={footerLink}
              to={{
                pathname: `/`,
              }}
            >
              <h3 css={{ fontWeight: 'bolder' }}>
                <b>
                  <span css={{ color: '#606060' }}>H</span>
                  <span css={{ color: 'white' }}>OST</span>
                  <span css={{ color: '#606060' }}>F</span>
                  <span css={{ color: 'white' }}>ILTER</span>
                </b>
              </h3>
            </NavLink>
            <p>
              The most comprehensive compare site in the gaming industry. New
              games are added as soon as they are released.
            </p>
          </div>
          <div
            css={[
              footerLink,
              {
                color: 'inherit',
                gridArea: 'column2',
                backgroundColor: '#1f1f1f',
              },
              footerLink,
            ]}
          >
            <NavLink
              css={footerLink}
              to={{
                pathname: `/host-area/`,
              }}
            >
              <h3 css={{ color: 'white' }}>
                <b>Adding Your Host</b>
              </h3>
            </NavLink>

            <p css={{ color: 'white' }}>
              If you would like to be added to our website please email us at{' '}
              <a
                css={{ color: 'inherit' }}
                href="mailto:admin@comparegameserverhosting.com"
              >
                HostFilter
              </a>
            </p>
          </div>
          <div className="admin-links" css={adminLinks2}>
            <h3>Site Admin</h3>
            <div className="admin-button-links" css={adminLinks}>
              <NavLink
                css={adminButtonLink}
                to={{
                  pathname: `/contact-us/`,
                }}
              >
                <p>{t('titles.contact-us')}</p>
              </NavLink>
              <NavLink
                css={adminButtonLink}
                to={{
                  pathname: `/roadmap/`,
                }}
              >
                <p>{t('titles.updates')}</p>
              </NavLink>
              <NavLink
                css={adminButtonLink}
                to={{
                  pathname: `/privacy/`,
                }}
              >
                <p>{t('titles.privacy-policy')}</p>
              </NavLink>
              <button css={adminButtonLink} onClick={resetStorage}>
                Reset cookies
              </button>
            </div>
          </div>
        </div>

        <div className="logo" css={logo}>
          <p>
            <span css={{ color: '#606060' }}>H</span>
            <span css={{ color: 'white' }}>OST</span>
            <span css={{ color: '#606060' }}>F</span>
            <span css={{ color: 'white' }}>ILTER Copyright 2014</span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Bottom
