import videoLinks from './videoLinks'
import { iframeWrapperStyle } from './styles'

type VideoIframeProps = {
  game: string
}

const VideoIframe: React.FC<VideoIframeProps> = ({ game }) => {
  if (!videoLinks[game]) return null

  return (
    <div css={iframeWrapperStyle} className="iframe-wrapper">
      <iframe
        title={game}
        src={videoLinks[game].video}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${videoLinks[game].video}?autoplay=1><img loading="lazy" src=${videoLinks[game].image} alt='${game}'><span>▶</span></a>`}
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default VideoIframe
