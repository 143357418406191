import { css } from '@emotion/react'

export const largeScreen = '@media only screen and (min-width: 1751px)'
export const mediumScreen = '@media only screen and (max-width: 1750px)'
export const smallScreen = '@media only screen and (max-width: 1200px)'
export const notTinyScreen = '@media only screen and (max-width: 800px)'
export const tinyScreen = '@media only screen and (max-width: 600px)'
export const largerThanSmallScreen =
  '@media only screen and (min-width: 1200px)'

export const tooltipStyle = css`
  line-height: 1em;
  font-size: 1em;
  opacity: 1;
`

export const noMobileStyle = css`
  ${smallScreen} {
    display: none;
  }
`

export const mobileOnlyStyle = css`
  ${largeScreen} {
    display: none;
  }
`

export const largerThanSmall = css`
  ${largerThanSmallScreen} {
    display: none;
  }
`

export const noTextDecoration = css`
  & a,
  & a:hover,
  & a:visited,
  & a:link {
    text-decoration: none;
    color: white;
  }
`
