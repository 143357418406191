/** @jsxImportSource @emotion/react */
import React from 'react'
import GuideImage from '../../helpers/guide-image'
import { guideStyle, guideTable } from '../styles'

const imagePath = 'images/reviews/rust'

const Rust = () => {
  return (
    <React.Fragment>
      <h2>HOW TO CREATE A RUST SERVER, MOD IT AND MANAGE IT.</h2>
      <div css={guideStyle} className="content">
        <p>
          In this guide you will learn all the details and the requirements
          needed to create an Rust dedicated server on your home computer, VPS
          or Dedicated Server, either you use Windows or Linux, you will be able
          to create an Rust dedicated server by following this guide carefully
          through all the illustrated steps and written instructions.
        </p>
        <p>
          We cover all the network configuration to help set up your internet
          router/firewall in order to make your server visible on the servers
          listing, we will also give you instructions on how to connect to your
          server and play on it.
        </p>
        <p>
          This guide also covers everything required to manage your server after
          creation. You will learn on how to install uMod plugins and add them
          to your server, how to update them, how to, modify your server
          settings from the basic ones like changing the server name, adding a
          password on the server and also the more advanced ones like changing
          in-game multipliers, XP levels and more.
        </p>
        <p>
          Sooner or later you will need or want to try out the admin commands
          and we got you covered, you can learn how to use admin commands like
          for spawning guns, turn god mode or just fly around, Rust does have a
          lot of admin commands and we give you the full list of them. If you
          are outside of your server and you still want to manage your players,
          send admin messages, or just check whos playing, you can do that using
          an RCON tool, we will give you full training on how to connect to your
          server RCON and execute commands on it.
        </p>
        <p>
          If you have any issues you can search in our troubleshooting section
          for a possible fix or solution, we cover most important and recurring
          issues on an Rust dedicated server, you will also want to learn on how
          to update your server when it’s needed like the uMod plugins when an
          update it’s released.
        </p>
        <p>
          We give you some extras also, you will like to have a system to auto
          restart your server every XXX hours, and backup your world now and
          ever just to be safe and secure about all the hours you lost grinding
          in-game.
        </p>
        <p>
          You can find some external links for more content, uMod forums has a
          very active community happy to help you in your questions, and
          official wiki also gives all the details from A to Z about Rust and
          how to play it.
        </p>
        <p>
          Please read and follow all the steps carefully one by one, and you
          will be able to accomplish all the tasks mentioned in this guide
          without any issue.
        </p>
        <p> (2.1) Prerequisites - Hardware</p>
        <p>
          You will need at last 4GB to 6GB memory RAM minimum, a vanilla Rust
          dedicated server without any players take around that amount of
          memory, the maximum memory recommend it’s based on the number of
          players you will have, the number and type of mods you use and how
          much you boost and adjust your server settings. We also recommend
          having a CPU clock at with at last 3.2Ghz for optimal performance,
          below you can find a table with estimated values on the hardware
          requires Vs server setup.
        </p>

        <table css={guideTable}>
          <thead>
            <tr>
              <th>
                <p>Players</p>
              </th>
              <th>
                <p>SERVER WITHOUT PLUGINS</p>
              </th>
              <th>
                <p>SERVER WITH PLUGINS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>30-60</td>
              <td>3.0Ghz - 4/6GB</td>
              <td>3.0Ghz - 6/8GB</td>
            </tr>
            <tr>
              <td>60-100</td>
              <td>3.0Ghz - 6/8GB</td>
              <td>3.2Ghz - 8/10GB</td>
            </tr>
            <tr>
              <td>100-200</td>
              <td>3.2/3.4Ghz - 8/10GB</td>
              <td>3.6/3.8Ghz - 10/12GB</td>
            </tr>
            <tr>
              <td>+200</td>
              <td>4.0Ghz - 12GB</td>
              <td>4.0+Ghz - 12+GB</td>
            </tr>
          </tbody>
        </table>
        <p>
          Results are estimated values tested under normal conditions on Windows
          Server 2012/2016, values may vary between different hardware
          specifications, O.S. or Linux distributions.
        </p>
        <p>Prerequisites - Hardware</p>
        <p>
          To create and manage your Rust dedicated server you will need to
          download and install certain dependencies for Rust server be able to
          run, you will also have to download and install some third-party tools
          in order to easy create and deploy your server. There are some
          optional applications and tools to help you on this task, find below a
          list of all required and optional applications and tools and choose
          the ones you will need and suit better for yourself.
        </p>

        <ul>
          <li>
            <p>Rust required dependencies to run</p>
          </li>
          <ul>
            <li>
              <p>
                <a href="https://www.microsoft.com/en-us/download/details.aspx?id=40784">
                  Microsoft Visual C++ 2013 Redistributable
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.microsoft.com/en-us/download/confirmation.aspx?id=35">
                  DirectX
                </a>
              </p>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <p>Tools required to create the Rust server on Windows</p>
          </li>
          <ul>
            <li>
              <p>
                <a href="https://store.steampowered.com/">Steam Client</a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://developer.valvesoftware.com/wiki/SteamCMD">
                  SteamCMD
                </a>
              </p>
            </li>
          </ul>

          <li>
            <p>Tools required to create the Rust server on Linux</p>
          </li>
          <ul>
            <li>
              <p>
                <a href="https://linuxgsm.com/lgsm/rustserver/">Linux GSM</a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://developer.valvesoftware.com/wiki/SteamCMD">
                  SteamCMD
                </a>
              </p>
            </li>
          </ul>
          <li>
            <p>Optional Tools</p>
          </li>
          <ul>
            <li>
              <p>
                <a href="https://www.rustadmin.com/">Rustadmin (RCON app)</a>
              </p>
            </li>
            <li>
              <p>Pre made Pre-made scripts used on this guide</p>
            </li>
          </ul>
        </ul>
        <h2>Windows server - Create server using Steam Client</h2>
        <p>
          In this section, you will learn how to easily create a Rust server
          using Steam client. Download and install Steam client from the
          (2.b)Prerequisites - Hardware section, once you have it installed on
          your computer, log in with your steam account or create a new one. If
          you follow the steps below one by one carefully and you will have an
          Rust dedicated server created and working soon you get into the last
          step.
        </p>
        <h3>Step 1</h3>
        <p>
          <b>
            Create a folder called SteamCMD on your C:\ drive, download SteamCMD
            and place it in that folder.
          </b>
        </p>

        <GuideImage imagePath={imagePath} imageName="step1" />
        <h3>Step 2</h3>
        <p>
          Create a folder called Rust on your C:\ drive, inside create a new
          Text Document file with the following SteamCMD command and save it as
          Update.bat
        </p>
        <code>
          C:\SteamCMD\SteamCMD.exe +login anonymous +force_install_dir C:\Rust\
          +app_update 258550 +quit
        </code>
        <GuideImage imagePath={imagePath} imageName="step2" />
        <h3>Step 3</h3>
        <p>
          <b>
            Inside Rust folder on your C:\ driver, create a new Text Document
            with the following command inside and save it as Start.bat
          </b>
        </p>
        <code>
          RustDedicated.exe -batchmode +server.hostname &quot;Server Name&quot;
          +server.seed 793197 +server.maxplayers 40 +server.worldsize 3000
          -logfile &quot;logfilename.log&quot;
        </code>
        <GuideImage imagePath={imagePath} imageName="step3" />
        <h3>Step 4</h3>
        <p>
          <b>
            Double click and execute the Start.bat file and you should see the
            server console opening, give it a few minutes for server to start
            and generate the map.
          </b>
        </p>
        <GuideImage imagePath={imagePath} imageName="step4" />
        <h3>Step 5</h3>
        <p>
          Open your Steam, go to menu View &gt; Servers, and change to
          Favourites tab, add your server exactly like the image below.
        </p>
        <GuideImage imagePath={imagePath} imageName="step5" />
        <h3>Step 6</h3>
        <p>
          Open Rust game on your Steam, go to Play Game menu, on the filters
          change to Favourited, your server will show up, just double click it
          to join it. Head over to (5) Firewall and port forwarding section to
          make your server visible to the public internet.
        </p>
        <GuideImage imagePath={imagePath} imageName="step6" />
        <h3>Step 7</h3>
        <p>
          If you want to customize your server settings create the following
          file <code>server\my_server_identity\cfg\server.cfg</code> and inside
          you can add your custom rust settings, you can find a full list in the
          <a href="https://developer.valvesoftware.com/wiki/Rust_Dedicated_Server">
            official wiki.
          </a>
        </p>
        <GuideImage imagePath={imagePath} imageName="step7" />
      </div>
    </React.Fragment>
  )
}

export default Rust
