/** @jsxImportSource @emotion/react */
import PictureSet from '../helpers/picture-set'
import PictureSetSmall from '../helpers/picture-set-small'
import { imageStyle, starsContainerStyle } from './styles'
import selectStar from './helpers/selectStars'

type StarsProps = {
  stars: number
}

const Stars: React.FC<StarsProps> = (props) => {
  return (
    <div css={starsContainerStyle} className="stars-container">
      <picture>
        <PictureSet
          imagePath="images/stars"
          imageName={selectStar(props.stars)}
        />
        <PictureSetSmall
          imagePath="images/stars"
          imageName={selectStar(props.stars)}
        />
        <img
          loading="lazy"
          css={imageStyle}
          src={`/images/stars/normal/png/${selectStar(props.stars)}.png`}
          alt="Stars"
        />
      </picture>
    </div>
  )
}

export default Stars
