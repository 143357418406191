import i18n from 'i18next';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json'; // Corrected path
import translationDE from './locales/de/translation.json'; // Corrected path
import translationFR from './locales/fr/translation.json';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['en'];
const supportedLngs = ['en', 'es', 'de', 'fr'];

i18n
  .use(HttpBackend) // load translation using xhr -> see /public/locales. We will add locales in the next step
  .use(LanguageDetector) // Detects user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources: {
      en: { translation: translationEN },
      es: { translation: translationES },
      de: { translation: translationDE },
      fr: { translation: translationFR },
    },
    lng: 'en', // language to use (if available, otherwise the first from supportedLngs)
    fallbackLng,
    debug: false,
    supportedLngs, // instead of deprecated 'whitelist'
    react: {
      useSuspense: false, // you can enable this to use Suspense
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'], // caching options
    },
  });

export default i18n;
