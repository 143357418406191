import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export type GameSpecDataType = {
  game: string
  ram_rec: string
  ram_min: string
  cpu_rec: string
  cpu_min: string
  graphics_rec: string
  graphics_min: string
  hd_rec: string
  hd_min: string
  directx_rec: string
  directx_min: string
  aff_rec: string
}

export type GameSpecDataState = {
  gameSpecData: Record<string, GameSpecDataType> // Map with game name as the key
  loading?: boolean
  error?: string | null // Add an error field to track the error state
}

export const gameSpecificationDataInitialState: GameSpecDataState = {
  gameSpecData: {}, // Initialize as an empty object instead of an array
  loading: false,
}

export const fetchSpecData = createAsyncThunk(
  'gameSpecificationData/fetchSpecData',
  async () => {
    const response = await fetch('/api/specs')
    const data = await response.json()

    return { gameSpecificationData: data }
  },
)

const GameSpecificationDataSlice = createSlice({
  name: 'gameSpecData',
  initialState: gameSpecificationDataInitialState,
  reducers: {
    // Modify the action to handle data as an object/map
    setGameSpecData: (
      state,
      action: PayloadAction<Record<string, GameSpecDataType>>,
    ) => {
      state.gameSpecData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecData.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSpecData.fulfilled, (state, action) => {
        state.loading = false
        state.gameSpecData = action.payload.gameSpecificationData.gameSpecData || {}
      })
      .addCase(fetchSpecData.rejected, (state, action) => {
        state.loading = false
        console.error('Error fetching game spec data:', action.error);

        state.error = 'There was an error fetching the game spec data'
      })
  },
})

export const { setGameSpecData } = GameSpecificationDataSlice.actions

export default GameSpecificationDataSlice.reducer
