const countryArray: string[] = [
  'usa',
  'en',
  'au',
  'br',
  'ca',
  'de',
  'fi',
  'fr',
  'gh',
  'in',
  'it',
  'ja',
  'nl',
  'nz',
  'pl',
  'ru',
  'sin',
  'sa',
  'es',
  'sw',
  'uk',
  'uae',
]

export default countryArray