import { css } from '@emotion/react'

export const buttonStyling = css`
  width: 80%;
  padding: 1em 0;
  background-color: #3f51b5;
  border-radius: 0.5em;
  outline: none;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: #3f51ff;
    transform: translateY(1em);
  }
`
