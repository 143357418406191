import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

const Analytics = () => {
  const location = useLocation()

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.GA_INITIALIZED) {
      ReactGA.initialize('G-Random', {
        // testMode: process.env.NODE_ENV === 'test',
        // debug: process.env.NODE_ENV === 'development',
      })
      window.GA_INITIALIZED = true
    }

    // Track page view
    ReactGA.set({ page: location.pathname })
    ReactGA.send('pageview')
  }, [location])

  return null
}

export default Analytics
