import React from 'react'
import { NavLink } from 'react-router-dom'
import { navBarLinkStyle } from '../styles'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

const adminPanel = css`
  position: fixed;
  display: flex;
  z-index: 3;
  flex-direction: column;
  background-color: darkgrey;
`

const AdminPanel = () => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <ul css={adminPanel} className="admin-panel">
        <NavLink css={navBarLinkStyle} to="/contact-us">
          {t('titles.contact-us')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/host-area">
          {t('titles.host-area')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/roadmap">
          {t('titles.updates')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/about-us">
          {t('titles.about-us')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/privacy">
          {t('titles.privacy-policy')}
        </NavLink>
      </ul>
    </React.Fragment>
  )
}

export default AdminPanel
