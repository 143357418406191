import { css } from '@emotion/react'

const noTextDecoration = css`
  & a,
  & a:hover,
  & a:visited,
  & a:link {
    text-decoration: none;
    color: white;
  }
`

const couponContainer = css`
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  min-width: 300px;
  font-size: 2rem;
  height: auto;
  margin: 0.5rem;
  background-color: grey;
  box-shadow: 5px 5px 5px #afafaf;
  &:hover {
    opacity: 0.5;
  }
`

const couponText = css`
  font-size: 1.5em;
  background-color: grey;
  border-radius: 5px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    padding: 0.3em;
    border-radius: 5px;
  }
  margin: 0 auto;
  text-align: center;
  p {
    margin: 0;
    font-size: 0.5em;
  }
`

export { noTextDecoration, couponContainer, couponText }
