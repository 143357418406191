import { css } from '@emotion/react'

const tooltipWrapperStyle = css`
  position: relative;
  display: inline-block;
`

const tooltipStyle = (x: number, y: number) => css`
  z-index: 400;
  position: absolute;
  left: 50%;
  transform: translateX(${x}%) translateY(${y}%); /* Center the tooltip */
  background-color: #333; /* Nicer background color */
  color: #fff; /* White text color */
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
`

const tooltipVisibleStyle = css`
  opacity: 1;
  visibility: visible;
`

export { tooltipWrapperStyle, tooltipStyle, tooltipVisibleStyle }
