/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const imageGameBanner = css`
  margin: 2px;
  object-fit: contain;
  min-width: 100px;
  min-height: 60px;
`

const imageContainer = css`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  min-height: 50px;
  justify-content: center;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const gameImage = css`
  height: 70px;
  width: 150px;
`

const homePageStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  a {
    &:hover,
    &:link,
    &:visited {
      color: white;
    }
  }
  margin-bottom: 1em;
`

const startLinkStyle = css`
  background-color: blue;
  border-radius: 10px;
  padding: 1em;
  margin: 1em;
`

const gameList = css`
  padding: 0;
  list-style-type: none;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
export {
  imageGameBanner,
  imageContainer,
  gameImage,
  homePageStyle,
  startLinkStyle,
  gameList,
}