import { BrowserRouter as Router } from 'react-router-dom'
import App from '../shared/App'
import { hydrateRoot } from 'react-dom/client'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { ErrorBoundary } from './bugsnag'
import { Provider } from 'react-redux'
import createStore from '../app/store'

declare global {
  interface Window {
    __INITIAL_DATA__: any
    GA_INITIALIZED: boolean
    BUGSNAG_INITIALIZED: boolean
  }
}
// bugsnag intialised from server code
// Initialize Bugsnag only in the browser environment
// if (typeof window !== 'undefined') {
//   if (process.env.BUGSNAG_API_KEY_REACT === undefined) {
//     throw new Error('BUGSNAG_API_KEY_REACT is not defined')
//   }

//   if (!window.BUGSNAG_INITIALIZED) {
//     console.log('Initializing Bugsnag', window.BUGSNAG_INITIALIZED)

//     Bugsnag.start({
//       apiKey: process.env.BUGSNAG_API_KEY_REACT as string,
//       plugins: [new BugsnagPluginReact(React)],
//     })
//     window.BUGSNAG_INITIALIZED = true
//   }
// }

const store = createStore(window.__INITIAL_DATA__)
const cache = createCache({ key: 'css' })
const Root = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router>
          <CacheProvider value={cache}>
            <App />
          </CacheProvider>
        </Router>
      </Provider>
    </ErrorBoundary>
  )
}

document.addEventListener('DOMContentLoaded', function () {
  const rootElement = document.getElementById('app')
  if (rootElement) {
    hydrateRoot(rootElement, <Root />)
  } else {
    console.error('Failed to find the root element')
  }
})
