import PictureSet from '../picture-set'

const toggleFullScreen = (e: React.MouseEvent<HTMLImageElement>) => {
  const target = e.currentTarget as HTMLImageElement

  if (!document.fullscreenElement) {
    target.requestFullscreen().catch((err) => {
      console.error(
        `Error attempting to enable full-screen mode: ${err.message} (${err.name})`,
      )
    })
  } else {
    document.exitFullscreen().catch((err) => {
      console.error(
        `Error attempting to exit full-screen mode: ${err.message} (${err.name})`,
      )
    })
  }
}

type GuideImageProps = {
  imageName: string
  imagePath: string
}

const GuideImage: React.FC<GuideImageProps> = (props) => {
  const { imageName, imagePath } = props
  const setupScreenshot = 'Server setup screenshot'
  return (
    <div className="image-container">
      <picture>
        <PictureSet imageName={imageName} imagePath={imagePath} />
        <img
          loading="lazy"
          alt={setupScreenshot}
          onClick={toggleFullScreen}
          src={`/${imagePath}thumb/png/${imageName}.png`}
        />
      </picture>
    </div>
  )
}

export default GuideImage
