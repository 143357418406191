/** @jsxImportSource @emotion/react */
import { buttonStyling } from './styles'

type ShowMoreHostButtonProps = {
  defaultNumberOfServersShown: number
  showHostTotal: number
  currentHost: string
  showAllHostsToggle: () => void
}

const ShowMoreHostButton: React.FC<ShowMoreHostButtonProps> = ({
  defaultNumberOfServersShown,
  showHostTotal,
  currentHost,
  showAllHostsToggle,
}) => {
  const showButton = () => {
    return currentHost === 'all' ? {} : { display: 'none' }
  }
  return (
    <div>
      {currentHost === 'all' ? (
        <button
          className="show-servers"
          css={[buttonStyling, showButton()]}
          onClick={showAllHostsToggle}
        >
          {showHostTotal === defaultNumberOfServersShown
            ? 'Show more Servers'
            : 'Show less servers'}
        </button>
      ) : null}
    </div>
  )
}

export default ShowMoreHostButton
