import React from 'react'
import { useTranslation } from 'react-i18next'
import UseMetaTags from '../meta-tags'
import VideoIFrame from '../helpers/video-iframe'
import { pageContainer, pageTitle, pageColumnText } from '../styles/admin'

const Feature = () => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <UseMetaTags
        ogDescription="Ark Survival Evolved Genesis Feature Page"
        ogImageUrl="https://www.comparegameserverhosting.com/images/ark-survival-evolved-genesis.jpg"
        ogTitle="Ark Survival Evolved Genesis Feature Page"
        title="Ark Survival Evolved Genesis Feature Page"
        content="Ark SE: rumoured to be dropping February 4th 2020."
        type="website"
        ogUrl="https://www.comparegameserverhosting.com/feature/ark-survival-evolved-genesis/"
      />
      <div css={pageContainer} className="feat-page-container">
        <h1 css={pageTitle} className="feat-page-title">
          Ark Survival Evolved Genesis{' '}
        </h1>
        <VideoIFrame game={'ark-survival-evolved-genesis'} />
        <div css={pageColumnText} className="feat-page-text">
          <p>{t('games-descriptions.feature')}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Feature
