import { css } from '@emotion/react'
import { smallScreen } from '../styles/global' // Add import statement for smallScreen

export const serverPageContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  color: white;
  background-color: #151443;
  box-sizing: border-box;
  width: 80%;
  margin: 0 auto 1em auto;
  padding: 1em;

  ${smallScreen} {
    width: 90%;
  }
`
