import PictureSet from '../helpers/picture-set'
import { imageStyle, imageLogoContainer } from './styles'

const imagePath = 'images/logos'

import { SerializedStyles } from '@emotion/react'

type ImageLogoProps = {
  imageName: string
  serverName: string
  css: SerializedStyles
  handleHostChange?: (e: React.MouseEvent<HTMLImageElement>) => void
}

const ImageLogo: React.FC<ImageLogoProps> = (props) => {
  const { imageName } = props
  return (
    <div css={imageLogoContainer} className="image-logos-container">
      <picture className="logo-picture-box">
        <PictureSet imagePath={imagePath} imageName={imageName} />
        <img
          loading="lazy"
          onClick={props.handleHostChange}
          data-host={props.serverName}
          css={imageStyle}
          alt="Logo"
          className="game-banner-images"
          // src={`/${imagePath}/normal/png/${imageName}.png`}
        />
      </picture>
    </div>
  )
}

export default ImageLogo
