import { css } from '@emotion/react'
import { largeScreen } from '../styles/global'

const imageStyle = css`
  object-fit: cover;
  width: 25px;
  height: 25px;
  padding: 1px;
  ${largeScreen} {
    width: 35px;
    height: 35px;
  }
`
const rowControl = css`
  position: relative;
  flex-basis: 24%;
  max-height: 100%;
`

export { imageStyle, rowControl }
