/** @jsxImportSource @emotion/react */
import { NavLink } from 'react-router-dom'
import PictureSet from '../helpers/picture-set'
import normaliseName from '../helpers/normalise-game-name'
import {
  displayGrid,
  gameBannerImagesStyle,
  highlighted,
  inActiveGame,
  notHighlighted,
  notInList,
  flexxy,
} from './styles'
import { ServerDataType } from '../host-row'
import { useAppSelector } from '../../app/hooks/typedHooks'
import { isEmpty } from 'lodash'

// map to game name from spreadsheet
const imagePath = 'images/bannerImages'

const getTransform = (isCurrentGame: boolean, isAll: boolean) => {
  return isCurrentGame && !isAll ? 'scale(1.25)' : 'scale(1)'
}

const linkStyling = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const getCurrentStyling = (
  isCurrentGame: boolean,
  isAll: boolean,
  currentGame: string,
) => {
  return (isCurrentGame && !isAll) || currentGame === 'all'
    ? highlighted
    : notHighlighted
}

const getOverStyling = (active: boolean) => {
  return active ? {} : notInList
}

const getImageStyle = (active: boolean) => {
  return active ? gameBannerImagesStyle : inActiveGame
}

const gameName = (game: string, currentGame: string) => {
  return game !== currentGame ? game : 'all'
}

export const topGames = [
  '7-days-to-die',
  'ark-survival-evolved',
  'arma-reforger',
  'conan-exiles',
  'CSGO',
  'dayz',
  'enshrouded',
  'factorio',
  'palworld',
  'project-zomboid',
  'rust',
  'soulmask',
  'squad',
  'satisfactory',
  'v-rising',
  'valheim',
]

type GameBannersProps = {
  showAllGames: boolean
  handleGameChange: (e: React.MouseEvent<HTMLImageElement>) => void
}

export const GameBanners: React.FC<GameBannersProps> = ({
  showAllGames,
  handleGameChange,
}) => {
  const { currentGame, currentHost } = useAppSelector(
    (state) => state.currentGameHost,
  )
  const { serverData, uniqueGames } = useAppSelector(
    (state) => state.gameServerData,
  )

  const getServerGames = () => {
    return currentHost !== 'all' ||
      showAllGames ||
      ![...topGames, 'all'].includes(currentGame)
      ? uniqueGames
      : topGames
  }

  const filterGames = getServerGames()

  let currentServerGames: string[] | undefined
  if (currentHost === 'all' || isEmpty(serverData)) {
    currentServerGames = getServerGames()
  } else {
    currentServerGames = serverData.find(
      (server: ServerDataType) => server.server_name === currentHost,
    )?.gameArray
  }

  return (
    <div id="banner-anchor" className="banner-images" css={displayGrid}>
      {filterGames.map((game) => {
        const active = currentServerGames?.indexOf(game) !== -1
        const isCurrentGame = game === currentGame
        const isAll = currentGame === 'all'

        const transform = getTransform(isCurrentGame, isAll)
        const currentStyling = getCurrentStyling(
          isCurrentGame,
          isAll,
          currentGame,
        )
        const overStyling = getOverStyling(active)
        const imageStyle = getImageStyle(active)

        if (active) {
          return (
            <div
              key={game}
              css={flexxy}
              className={`banner-image-container ${game}`}
            >
              <NavLink
                css={{ color: 'inherit' }}
                to={`/servers/${currentHost}/${gameName(game, currentGame)}`}
              >
                <div
                  css={[
                    linkStyling,
                    currentStyling,
                    overStyling,
                    imageStyle,
                    { transform },
                  ]}
                >
                  <picture css={overStyling}>
                    <PictureSet imagePath={imagePath} imageName={game} />
                    <img
                      alt={`${game}-game`}
                      data-value={game}
                      onClick={handleGameChange}
                      css={[imageStyle, currentStyling]}
                      className="game-banner-images"
                    />
                  </picture>
                  <p>{normaliseName(game)}</p>
                </div>
              </NavLink>
            </div>
          )
        }

        return null
      })}
    </div>
  )
}

export default GameBanners
