import { css } from '@emotion/react'

const iframeWrapperStyle = css`
  position: 'relative';
  overflow: 'hidden';
  // paddingBottom: '56.25%';
  gridarea: 'video';
`

export { iframeWrapperStyle }
