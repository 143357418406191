const reviewColors = {
  lowest: 'red',
  low: 'darkorange',
  medium: 'orange',
  high: 'lightgreen',
  highest: 'green',
}

const colorSelector = (rating: number) => {
  let selectedColor

  switch (true) {
    case rating <= 1.0:
      selectedColor = reviewColors.lowest
      break
    case rating < 2.0:
      selectedColor = reviewColors.low
      break
    case rating < 3.0:
      selectedColor = reviewColors.medium
      break
    case rating < 4.0:
      selectedColor = reviewColors.high
      break
    case rating <= 5.0:
      selectedColor = reviewColors.highest
  }
  return selectedColor
}

export default colorSelector
