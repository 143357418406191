/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  FaMemory,
  FaMicrochip,
  FaHdd,
  FaTv,
  FaCompactDisc,
} from 'react-icons/fa'

import { useAppSelector, useAppDispatch } from '../../../app/hooks/typedHooks'
import { fetchSpecData } from '../../../app/slices/gameSpecificationSlice'
import normaliseName from '../../helpers/normalise-game-name'
import { noMobileStyle, mobileOnlyStyle } from '../../styles/global'
import {
  localContainerStyle,
  specsContainerStyle,
  recommendedBlockStyle,
  minimumBlockStyle,
  specDetailsStyle,
} from './styles'
import MetaTags from '../../meta-tags'

const GameSpec = () => {
  const { game } = useParams<{ game: string }>() // Extract the game name from the URL
  const dispatch = useAppDispatch()

  // Get the game spec data and loading/error states from Redux
  const { gameSpecData, loading, error } = useAppSelector(
    (state) => state.gameSpecificationData,
  )

  const spec = game ? gameSpecData[game] : null
  useEffect(() => {
    if (!spec) {
      // If game does not exist, replace the URL and optionally navigate
      const newUrl = '/server-requirements'
      window.history.replaceState(null, '', newUrl)
    }
  }, [spec])
  // Fetch the spec data if it's not already available
  useEffect(() => {
    if (!Object.keys(gameSpecData).length) {
      dispatch(fetchSpecData())
    }
  }, [dispatch, gameSpecData])

  // Loading and error states
  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (!spec) {
    return null
  }

  const iconMap = {
    Ram: <FaMemory />,
    CPU: <FaMicrochip />,
    Graphics: <FaTv />,
    HD: <FaHdd />,
    DirectX: <FaCompactDisc />,
  }
  type IconLabel = 'Ram' | 'CPU' | 'Graphics' | 'HD' | 'DirectX'
  type IconMap = {
    [key in IconLabel]: JSX.Element
  }

  const metaTitle = `System Requirements for ${normaliseName(spec.game)}`
  const metaContent = `The recommended system requirements for a PC for ${normaliseName(spec.game)}`

  const renderSpecDetail = (
    label: keyof IconMap,
    value: string,
    displayLabel: string,
  ) => (
    <div css={specDetailsStyle}>
      <MetaTags
        title={metaTitle}
        content={metaContent}
        ogTitle={metaTitle}
        type="website"
        ogUrl={`https://www.comparegameserverhosting.com/system-requirements/${game}`}
        ogDescription={metaContent}
        ogImageUrl={`https://www.comparegameserverhosting.com/images/ogImages/logos/normal/games/${game}.png`}
      />
      <p css={noMobileStyle}>{label}</p>
      <p>
        <span css={mobileOnlyStyle}>
          {iconMap[label]} {/* Show the corresponding icon on mobile */}
        </span>
        {value}
      </p>
    </div>
  )

  const renderRecommendedSpecs = () => (
    <div css={recommendedBlockStyle}>
      <h3>Recommended Specifications</h3>
      {renderSpecDetail('Ram', spec.ram_rec, 'Recommended Ram')}
      {renderSpecDetail('CPU', spec.cpu_rec, 'Recommended CPU')}
      {renderSpecDetail('Graphics', spec.graphics_rec, 'Recommended Graphics')}
      {renderSpecDetail('HD', spec.hd_rec, 'Recommended HD')}
      {renderSpecDetail('DirectX', spec.directx_rec, 'Recommended DirectX')}
    </div>
  )

  const renderMinimumSpecs = () => (
    <div css={minimumBlockStyle}>
      <h3>Minimum Specifications</h3>
      {renderSpecDetail('Ram', spec.ram_min, 'Minimum Ram')}
      {renderSpecDetail('CPU', spec.cpu_min, 'Minimum CPU')}
      {renderSpecDetail('Graphics', spec.graphics_min, 'Minimum Graphics')}
      {renderSpecDetail('HD', spec.hd_min, 'Minimum HD')}
      {renderSpecDetail('DirectX', spec.directx_min, 'Minimum DirectX')}
    </div>
  )

  return (
    <div className="specs-container" css={specsContainerStyle}>
      <div className="local-container" css={localContainerStyle}>
        <h2 css={{ textAlign: 'center' }}>{normaliseName(spec.game)}</h2>
        <div className="specs-blocks" css={specsContainerStyle}>
          {renderRecommendedSpecs()}
          {renderMinimumSpecs()}
        </div>
      </div>
    </div>
  )
}

export default GameSpec
