/** @jsxImportSource @emotion/react */
import React from 'react'
import SortArrows from '../../sort-arrows'
import { useTranslation } from 'react-i18next'
import { mobileOnlyStyle } from '../../styles/global'

import {
  pointer,
  mobileSort,
  mobileSortContainer,
  serverBlockDediStyle,
  singleServerBlockDediTitleStyle,
} from '../../styles/titles'
import { SortEnum } from '../../grid'

type DedicatedTitlesProps = {
  sortByHosts: () => void
  sortByReviews: () => void
  sortByRating: () => void
  sortByCGSH: () => void
  sortByEstablished: () => void
  currentSort: SortEnum
  sorts: {
    cpuSort: boolean
    memorySort: boolean
    minPriceSort: boolean
    establishedSort: boolean
    reviewsSort: boolean
    hostSort: boolean
    CGSHSort: boolean
    ratingSort: boolean
  }
}

export const DedicatedTitles: React.FC<DedicatedTitlesProps> = (props) => {
  const { t } = useTranslation()
  const {
    sortByHosts,
    sortByReviews,
    sortByRating,
    sortByCGSH,
    sortByEstablished,
    currentSort,
    sorts,
  } = props
  return (
    <React.Fragment>
      <div css={serverBlockDediStyle} className={`server-block titles-block`}>
        <div
          css={singleServerBlockDediTitleStyle}
          className="single-server-block-title"
        >
          <div className="main-image-logo">
            <div css={pointer} onClick={sortByHosts}>
              <SortArrows
                title={t('titles.hosting-company')}
                sortBy={SortEnum.hosts}
                currentSort={currentSort}
                sort={sorts.hostSort}
              />
            </div>
          </div>
          <div className="trustpilot-area">
            <div css={pointer} onClick={sortByRating}>
              <SortArrows
                title={t('titles.trustpilot-rating')}
                sortBy={SortEnum.overall_rating}
                currentSort={currentSort}
                sort={sorts.ratingSort}
              />
            </div>
            <div css={pointer} onClick={sortByReviews}>
              <SortArrows
                currentSort={currentSort}
                title={t('titles.total-reviews')}
                sortBy={SortEnum.review_count}
                sort={sorts.reviewsSort}
              />
            </div>
            <div css={pointer} onClick={sortByCGSH}>
              <SortArrows
                currentSort={currentSort}
                title={t('titles.our-score')}
                sortBy={SortEnum.cgsh}
                sort={sorts.CGSHSort}
              />
            </div>
          </div>
          <div className="price-icon">
            <p>{t('titles.price-icon')}</p>
          </div>
          <div className="locations">
            <p>{t('titles.locales')}</p>
          </div>
          <div className="Memory">
            <p>{t('titles.memory')}</p>
          </div>
          <div className="CPU">
            <p>{t('titles.cpu')}</p>
          </div>
          <div className="hdd">
            <p>{t('titles.hdd')}</p>
          </div>
          <div className="ip-group">
            <span>
              {' '}
              <span css={mobileOnlyStyle}>IPs: </span>
              {t('titles.ip')}
            </span>
            <span css={{ display: 'inline-block' }}>
              <span css={mobileOnlyStyle}>Extra: </span>
              {t('titles.extra-ips')}
            </span>
            <span css={{ display: 'inline-block' }}>
              <span css={mobileOnlyStyle}>Speed: </span>
              {t('titles.connection-speed')}
            </span>
            <span css={{ display: 'inline-block' }}>
              <span css={mobileOnlyStyle}>Limit: </span>
              {t('titles.data-limit')}
            </span>
          </div>
          <div className="support">
            <p>{t('titles.support')}</p>
          </div>
          <div
            className="established"
            css={pointer}
            onClick={sortByEstablished}
          >
            <SortArrows
              title={t('titles.year-founded')}
              sortBy={SortEnum.established}
              currentSort={currentSort}
              sort={sorts.establishedSort}
            />
          </div>
        </div>
      </div>
      <div id="site-sorts" css={[mobileOnlyStyle, mobileSortContainer]}>
        <div css={mobileSort} onClick={sortByRating}>
          <SortArrows
            title={t('titles.trustpilot-rating')}
            sortBy={SortEnum.overall_rating}
            currentSort={currentSort}
            sort={sorts.ratingSort}
          />
        </div>
        <div css={mobileSort} onClick={sortByHosts}>
          <SortArrows
            title={t('titles.hosting-company')}
            sortBy={SortEnum.hosts}
            currentSort={currentSort}
            sort={sorts.hostSort}
          />
        </div>
        <div css={mobileSort} onClick={sortByCGSH}>
          <SortArrows
            currentSort={currentSort}
            title={t('titles.our-score')}
            sortBy={SortEnum.cgsh}
            sort={sorts.CGSHSort}
          />
        </div>
        <div className="base-price" css={mobileSort} onClick={sortByReviews}>
          <SortArrows
            currentSort={currentSort}
            title={t('titles.total-reviews')}
            sortBy={SortEnum.review_count}
            sort={sorts.reviewsSort}
          />
        </div>
        <div
          className="established"
          css={mobileSort}
          onClick={sortByEstablished}
        >
          <SortArrows
            title={t('titles.year-founded')}
            sortBy={SortEnum.established}
            currentSort={currentSort}
            sort={sorts.establishedSort}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default DedicatedTitles
