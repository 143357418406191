import { css } from '@emotion/react'
import {
  smallScreen,
  largeScreen,
  notTinyScreen,
  mediumScreen,
  tinyScreen,
} from '../global' // adjust the import path as necessary

export const pointer = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const mobileSort = css`
  color: whitesmoke;
  background-color: black;
  padding: 1em;
  border: 1px solid white;
  flex-grow: 1;
  text-align: center;
`

export const mobileSortContainer = css`
  display: flex;
  color: white;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  ${smallScreen} {
    display: none;
  }
`

export const serverBlockStyle = css`
  width: 100%;
  border: 1px solid grey;
  color: rgb(237, 224, 223);
  background-color: #1f1f1f;
  margin: 0 auto;
  margin-bottom: 0.25em;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
  ${smallScreen} {
    display: none;
  }
`

export const singleServerBlockTitleStyle = css`
  display: grid;
  font-weight: bolder;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  ${tinyScreen} {
    margin: 1em;
    display: grid;
    align-items: start;
    grid-template-columns: 1% 1fr 1fr 1fr 1%;
    grid-template-rows: auto;
    grid-gap: 1em;
    grid-template-areas:
      '. mainImageLogo trustpilotArea platforms .'
      '. established locations support .'
      '. slots slots controlPanel .'
      '. link link link .';
    justify-content: center;
  }
  .main-image-logo {
    .image-logos {
      display: block;
      cursor: pointer;
    }
  }
  ${smallScreen} {
    display: none;
    .locations,
    .established,
    .control-panel,
    .platforms,
    .support {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    .established {
      flex-direction: row;
    }
  }
  ${mediumScreen} {
    display: grid;
    text-align: center;
    grid-template-rows: 1fr;
    align-items: center;
    grid-template-columns: 3fr 2fr 2fr 1fr 1fr 0.85fr 1fr 2fr;
    font-size: 0.65vw;
    grid-gap: 0.2em;
    grid-template-areas: 'mainImageLogo trustpilotArea slots locations platforms controlPanel support established link';
  }
  ${largeScreen} {
    display: grid;
    text-align: center;
    grid-template-rows: 1fr;
    align-items: center;
    grid-template-columns: 3fr 2fr 2fr 1fr 1fr 0.85fr 1fr 2fr;
    font-size: 0.65vw;
    grid-gap: 0.2em;
    grid-template-areas: 'mainImageLogo trustpilotArea slots locations platforms controlPanel support established link';
  }
`

export const serverBlockDediStyle = css`
  border: 1px solid grey;
  color: rgb(237, 224, 223);
  background-color: #1f1f1f;
  margin: 0 auto;
  margin-bottom: 1em;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
  width: 80%;
  ${smallScreen} {
    border: none;
  }
`

export const singleServerBlockDediTitleStyle = css`
  font-weight: bolder;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  ${notTinyScreen} {
    margin: 1em;
    display: grid;
    align-items: start;
    grid-template-columns: 1% 1fr 1fr 1fr 1%;
    grid-template-rows: auto;
    grid-gap: 1em;
    grid-template-areas:
      '. mainImageLogo trustpilotArea platforms .'
      '. established locations support .'
      '. slots slots controlPanel .'
      '. link link link .';
    justify-content: center;
  }
  .main-image-logo {
    .image-logos {
      cursor: pointer;
    }
  }
  ${smallScreen} {
    .locations,
    .established,
    .control-panel,
    .platforms,
    .support {
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    .established {
      flex-direction: row;
    }
  }
  ${largeScreen} {
    display: grid;
    text-align: center;
    grid-template-rows: 1fr;
    align-items: center;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    font-size: 0.65vw;
    grid-gap: 0.2em;
    grid-template-areas: 'mainImageLogo trustpilotArea price memory hdd ipGroup locations os support established link';
  }
`
