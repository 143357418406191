import { css } from '@emotion/react'
import { smallScreen } from '../styles/global'

export const starsContainerStyle = css`
  width: auto;
  margin: 0 auto;
`

export const imageStyle = css`
  width: 150px;
  height: 75px;
  object-fit: contain;

  ${smallScreen} {
    width: 90px;
    height: 45px;
  }
`
