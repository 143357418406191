import { css } from '@emotion/react'
import { largeScreen } from '../styles/global'

export const featuredHost = css`
  margin: 0 auto;
  display: flex;
  color: white;
  background-color: purple;
  ${largeScreen} {
    width: 80%;
  }
`

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `
// const fadeOut = keyframes`
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 2;
//   }
// `

// export const fadeInAnimation = css`
//   animation: ${fadeIn} 0.5s ease-in-out;
// `

// export const fadeInAnimation = css`
//   animation: ${fadeIn} 0.5s ease-in-out;
// `
