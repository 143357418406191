/** @jsxImportSource @emotion/react */
import PictureSet from '../helpers/picture-set'
import amazonLinksData from './links'
import {
  amazonImages,
  amazonLinkContainer,
  amazonLinkContainerFirst,
  amazonLinks,
  amazonTextContainer,
  whiteLink,
} from './styles'

const AmazonLinks = () => {
  const imagePath = 'images/amazonAffiliate'

  return (
    <div css={amazonLinks}>
      {amazonLinksData.map((linkData, index) => (
        <div
          key={index}
          css={index === 0 ? amazonLinkContainerFirst : amazonLinkContainer}
        >
          <a css={whiteLink} href={linkData.href}>
            <div className="amazon-text-container" css={amazonTextContainer}>
              <p>{linkData.text[0]}</p>
              <p>{linkData.text[1]}</p>
            </div>
            <picture>
              <PictureSet
                imagePath={imagePath}
                imageName={linkData.imageName}
              />
              <img css={amazonImages} alt={linkData.description} />
            </picture>
          </a>
        </div>
      ))}
    </div>
  )
}

export default AmazonLinks
