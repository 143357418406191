import capitalise from '../capitalise-string'

const normaliseName = (name: string) => {
  return name
    .split('-')
    .map((name) => capitalise(name))
    .join(' ')
}

export default normaliseName
