import { combineReducers } from '@reduxjs/toolkit'
import gameServerDataReducer from './slices/gameServerDataSlice'
import currentGameHostReducer from './slices/currentGameHostSlice'
import gameSpecDataReducer from './slices/gameSpecificationSlice'

const rootReducer = combineReducers({
  gameServerData: gameServerDataReducer,
  currentGameHost: currentGameHostReducer,
  gameSpecificationData: gameSpecDataReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
