import { css } from '@emotion/react'
import { smallScreen } from '../styles/global'

const imageStyle = css`
  width: 50px;
  height: 50px;
  ${smallScreen} {
    max-width: 30vw;
  }
`

const imageLogosStyle = css`
  color: white;
  font-weight: bolder;
`

export { imageStyle, imageLogosStyle }
