import { css } from '@emotion/react'
import { largeScreen, notTinyScreen, smallScreen } from '../global' // adjust the import path as necessary

export const noDecoration = css`
  text-decoration: none;
`

export const slotRangesOptionsStyle = css`
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const serverGamesListStyle = css`
  ${largeScreen} {
    text-align: center;
    display: flex;
  }
  ${smallScreen} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    justify-items: space-between;
  }
`

export const noMobileStyle = css`
  ${smallScreen} {
    display: none;
  }
`

export const mobileOnlyStyle = css`
  ${largeScreen} {
    display: none;
  }
`

export const serverGamesTitleStyle = css`
  justify-self: right;
`

export const serverGamesNumbersStyle = css`
  justify-self: center;
`

export const hostLink = css`
  margin: 0.5em;
  cursor: pointer;
  width: 80%;
  display: block;
  background-color: rgb(36 120 73 / 26%);
  text-align: center;
  padding: 0.5em;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  transition: background-color 1s ease;

  &:hover {
    background-color: red;
  }

  ${notTinyScreen} {
    width: 100%;
  }
`
