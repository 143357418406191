/** @jsxImportSource @emotion/react */
import React from 'react'
import GuideImage from '../../helpers/guide-image'
import { guideTable, guideStyle } from '../styles'

const imagePath = 'images/reviews/ark-survival-evolved'

const ArkSurvivalEvolved = () => {
  return (
    <React.Fragment>
      <h2>
        How to create an ARK: Survival Evolved Server, mod it and manage it
      </h2>
      <div css={guideStyle} className="content">
        <p>
          In this guide you will learn all the details and the requirements
          needed to create an ARK: Survival Evolved dedicated server on your
          home computer, VPS or Dedicated Server, either you use Windows or
          Linux, you will be able to create an ARK dedicated server by following
          this guide carefully through all the illustrated steps and written
          instructions.
        </p>
        <p>
          We cover all the network configuration to help set up your internet
          router/firewall in order to make your server visible on the servers
          listing, we will also give you instructions on how to connect to your
          server and play on it.
        </p>
        <p>
          This guide also covers everything required to manage your server after
          creation. You will learn on how to install Steam Workshop Mods and add
          them to your server, how to update them, how to, modify your server
          settings from the basic ones like changing the server name, adding a
          password on the server til the more advanced ones like changing
          in-game multipliers, XP levels and more.
        </p>
        <p>
          Sooner or later you will need or want to try out the admin commands
          and we have got you covered, you can learn how to use admin commands
          to for spawning dinos, turn god mode on or just fly around, ARK does
          have a lot of admin commands and we give you the full list of them.
        </p>
        <p>
          If you are outside of your server and you still want to manage your
          players, send admin messages or just check whos playing, you can do
          that using an RCON tool, we will give you full training on how to
          connect to your server RCON and execute commands on it.
        </p>
        <p>
          If you have any issues you can search in our troubleshooting section
          for a possible fix or solution, we cover most important and recurring
          issues on an ARK dedicated server, you will also want to learn on how
          to update your server when it’s needed like the Steam Workshop mods
          when an update it’s released.
        </p>
        <p>
          We give you some extras also, you will like to have a system to auto
          restart your server every XXX hours, and backup your world now and
          ever just to be safe and secure about all the hours you lost grinding
          in-game.
        </p>
        <p>
          You can find some external links for more content, ARK hub has a very
          active community happy to help you in your questions, and official
          wiki also gives all the details from A to Z about ARK and how to play
          it.
        </p>
        <p>
          Please read and follow all the steps carefully one by one, and you
          will be able to accomplish all the tasks mentioned in this guide
          without any issue.
        </p>
        <p>(2.1) Prerequisites - Hardware</p>
        <p>
          You will need at least 4GB to 6GB memory RAM minimum, a vanilla ARK
          dedicated server without any players take around that amount of
          memory, the maximum memory recommend it’s based on the number of
          players you will have, the number and type of mods you use and how
          much you boost and adjust your server settings. We also recommend
          having a CPU clock at with at last 3.2Ghz for optimal performance,
          below you can find a table with estimated values on the hardware
          requires Vs server setup.
        </p>
        <table css={{ guideTable }}>
          <colgroup>
            <col width="80" />
            <col width="237" />
            <col width="247" />
          </colgroup>
          <tbody>
            <tr>
              <td>
                <p>PLAYERS</p>
              </td>
              <td>
                <p>SERVER WITHOUT MODS</p>
              </td>
              <td>
                <p>SERVER WITH MODS</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>10 - 30</p>
              </td>
              <td>
                <p>3.0Ghz - 4/6GB</p>
              </td>
              <td>
                <p>3.0Ghz - 6/8GB</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>30 - 60</p>
              </td>
              <td>
                <p>3.0Ghz - 6/8GB</p>
              </td>
              <td>
                <p>3.2Ghz - 8/10GB</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>60 - 90</p>
              </td>
              <td>
                <p>3.2/3.4Ghz - 8/10GB</p>
              </td>
              <td>
                <p>3.6/3.8Ghz - 10/12GB</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>+100</p>
              </td>
              <td>
                <p>4.0Ghz - 12GB</p>
              </td>
              <td>
                <p>4.0+Ghz - 12+GB</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Results are estimated values tested under normal conditions on Windows
          Server 2012/2016, values may vary between different hardware
          specifications, O.S. or Linux distributions.
        </p>
        <p>(2.2) Prerequisites - Hardware</p>
        <p>
          To create and manage your ARK dedicated server you will need to
          download and install certain dependencies for ARK server be able to
          run, you will also have to download and install some third-party tools
          in order to easy create and deploy your server. There are some
          optional applications and tools to help you on this task, find below a
          list of all required and optional applications and tools and choose
          the ones you will need and suit better for yourself.
        </p>
        <ul>
          <li>
            <p>ARK required dependencies to run</p>
          </li>
          <ul>
            <li>
              <p>
                <a href="https://www.microsoft.com/en-us/download/details.aspx?id=40784">
                  Microsoft Visual C++ 2013 Redistributable
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://www.microsoft.com/en-us/download/confirmation.aspx?id=35">
                  DirectX
                </a>
              </p>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <p>Tools required to create the ARK server on Windows</p>
          </li>
          <ul>
            <li>
              <p>
                <a href="https://store.steampowered.com/">Steam Client</a>
              </p>
            </li>
            <li>
              <p>
                <a href="http://arkservermanager.freeforums.net/thread/5193/downloads">
                  ARK Server Manager
                </a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://developer.valvesoftware.com/wiki/SteamCMD">
                  SteamCMD
                </a>
              </p>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <p>Tools required to create the ARK server on Linux</p>
          </li>
          <ul>
            <li>
              <p>
                <a href="https://linuxgsm.com/lgsm/arkserver/">LinuxGSM</a>
              </p>
            </li>
            <li>
              <p>
                <a href="https://developer.valvesoftware.com/wiki/SteamCMD">
                  SteamCMD
                </a>
              </p>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <p>Optional tools</p>
          </li>
          <ul>
            <li>
              <p>
                <a href="https://steamcommunity.com/linkfilter/?url=http://rusty.sadface.co.uk/download/ARKon/ARKon.zip">
                  ARKon (RCon App)
                </a>
              </p>
            </li>
            <li>
              <p>Pre-made scripts used on this guide</p>
            </li>
          </ul>
        </ul>
        <p>(3.1) Windows server - Create server using Steam Client</p>
        <p>
          In this section, you will learn how to easily create an ARK server
          using Steam client. Download and install Steam client from the
          (2.b)Prerequisites - Hardware section, once you have it installed on
          your computer, log in with your steam account or create a new one.
        </p>
        <p>
          If you follow the steps below one by one carefully and you will have
          an ARK dedicated server created and working soon you get into the last
          step.
        </p>
        <p>STEP 1</p>
        <p>
          In your Steam Library, search for ARK: Survival Evolved Dedicated
          Server under Tools section.
        </p>
        <GuideImage imagePath={imagePath} imageName="step1" />
        <p>STEP 2</p>
        <p>
          Run the ARK: Survival Evolved Dedicated Server and select which map
          you want to start the server with one first time to generate all map
          files.
        </p>
        <GuideImage imagePath={imagePath} imageName="step2" />
        <p>STEP 3</p>
        <p>
          The server will take a few minutes to fully start depending on your
          machine hardware, once the server it’s fully started and map files
          generated you will see a window exaclty like this.
        </p>
        <GuideImage imagePath={imagePath} imageName="step3" />
        <p>STEP 4</p>
        <p>
          Close the server window to adjust the server name and any other game
          settings. Right-click on ARK: Survival Evolved Dedicated Server tool,
          select Properties, inside change to Local Files tab and press the
          Browse Local Files button.
        </p>

        <GuideImage imagePath={imagePath} imageName="step4" />
        <p>STEP 5</p>
        <p>
          It will open a Windows Explorer and you will see the root folder/file
          structure of your server (1) navigate on the folders until you get
          into this folder: ShooterGame\Saved\Config\WindowsServer\ inside you
          will see the configuration files (2) edit the GameUserSettings.ini one
          with your Notepad.
        </p>
        <GuideImage imagePath={imagePath} imageName="step5" />
        <p>STEP 6</p>
        <p>
          Once you have GameUserSettings.ini file open, scroll down to the
          bottom and change then SessionName and MaxPlayer as a test, you can do
          it like the example image below, in the end, close Notepad and Save
          the file.
        </p>
        <GuideImage imagePath={imagePath} imageName="step6" />
        <p>STEP 7</p>
        <p>
          Return to your steam, launch the ARK: Survival Evolved Dedicated
          Server tool with the map desired (STEP 2) wait for the server to fully
          start (STEP 3) then open your Steam, go to menu View &gt; Servers, and
          change to Favourites tab, add your server exactly like the image
          below.
        </p>
        <GuideImage imagePath={imagePath} imageName="step7" />
        <p>STEP 8</p>
        <p>
          Open ARK game on your Steam, go to Join ARK menu, on the bottom change
          the Session Filter to Favourites, your server will show up, just
          double click it to join it. Head over to (5) Firewall and port
          forwarding section to make your server visible to the public internet.
        </p>
        <GuideImage imagePath={imagePath} imageName="step8" />
      </div>
    </React.Fragment>
  )
}
export default ArkSurvivalEvolved
