/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import UseMetaTags from '../meta-tags'
import { pageText, pageContainer, pageTitle } from '../styles/admin'
import useAnalytics from '../hooks/useAnalytics'

const AddHost = () => {
  useAnalytics({
    category: 'Add Host',
    action: 'Viewed Add Host page',
    label: 'Add Host',
  })
  const { t } = useTranslation()
  return (
    <div css={pageContainer} className="add-host-page-container">
      <UseMetaTags
        title="Want to sign up to HostFilter for your Game Server Provider"
        content="Sign up to Hostfilter"
        ogDescription="Get your games on HostFilter"
        ogTitle="HostFilter sign up"
        type="website"
        ogUrl="https://comparegamerserverhosting/host-area"
        ogImageUrl="https://comparegameserverhosting/images/ogImages/logos/normal/all.png"
      />
      <h1 css={pageTitle} className="add-host-page-title">
        {t('introductions.add-host-title')}
      </h1>
      <div css={pageText} className="add-host-page-text">
        <p>
          {t('introductions.add-host-text')}
          <a href="mailto:admin@comparegameserverhosting.com">
            Hostfilter
          </a>{' '}
        </p>
      </div>
    </div>
  )
}

export default AddHost
