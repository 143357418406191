/** @jsxImportSource @emotion/react */
import PictureSet from '../helpers/picture-set'
import { imageStyle, imageLogosStyle } from './styles'

type SupportLogoProps = {
  imageName: string
}

const SupportLogo: React.FC<SupportLogoProps> = (props) => {
  const imagePath = 'images/supportImages'
  const { imageName } = props
  return (
    <div css={imageLogosStyle}>
      {/* <Tooltip title={t(`support.${imageName}`)}> */}
      <div>
        <picture>
          <PictureSet imagePath={imagePath} imageName={imageName} />
          <img
            loading="lazy"
            key={imageName}
            css={imageStyle}
            src={`/${imagePath}/normal/png/${imageName}.png`}
            alt={imageName}
          />
        </picture>
      </div>
      {/* </Tooltip> */}
    </div>
  )
}

export default SupportLogo
