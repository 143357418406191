import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

export type MetaTagsProps = {
  title: string
  content: string
  ogTitle: string
  type: string
  ogUrl: string
  ogDescription: string
  ogImageUrl: string
}

const MetaTags: React.FC<MetaTagsProps> = ({
  title,
  content,
  ogTitle,
  type,
  ogUrl,
  ogDescription,
  ogImageUrl,
}) => {
  const path = useLocation().pathname
  return (
    <Helmet>
      <title>{title} </title>
      <meta name="description" content={content} />
      <link
        rel="canonical"
        href={`https://www.comparegameserverhosting.com${path}`}
      />
      <meta
        property="og:description"
        content={ogDescription || 'Compare game server hosts'}
      />
      <meta property="og:site_name" content="HostFilter" />
      <meta property="og:title" content={ogTitle || 'title'} />
      <meta property="og:type" content={type || 'website'} />
      <meta property="og:url" content={ogUrl || 'url'} />
      <meta property="og:image" content={ogImageUrl || 'missing-picture'} />
    </Helmet>
  )
}

export default MetaTags
