import { useState, useEffect, Suspense, lazy } from 'react'
import { NavLink } from 'react-router-dom'
import routes from './routes'
import { Route, Routes } from 'react-router-dom'
import Navbar from './nav-bar'
import Footer from './footer'
import Unknown from './unknown'
import { updateData } from './road-map/updateData'
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'
import SideDrawer from './nav-bar/side-drawer'
import BackDrop from './nav-bar/back-drop'
import ReactGA4 from 'react-ga4'
import { versionNumberStyle } from './styles'

const Analytics = lazy(() => import('../shared/services/analytics'))

const { version, month, day, year } = updateData[0]

type AppProps = {}

const App: React.FC<AppProps> = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.GA_INITIALIZED) {
      ReactGA4.initialize('G-24QY1PP4XK')
      window.GA_INITIALIZED = true
    }
  }, [])

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen((prev) => !prev)
  }

  const backdropClickHandler = () => {
    setSideDrawerOpen(false)
  }

  const backdrop = sideDrawerOpen ? (
    <BackDrop click={backdropClickHandler} />
  ) : null

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<div>Loading...</div>}>
        <Analytics />
      </Suspense>
      <div
        css={{
          backgroundColor: '#363636',
          width: '100%',
          'a, a:link, a:visited, a:hover': {
            textDecoration: 'none',
          },
        }}
      >
        <NavLink to="/roadmap">
          <div
            css={versionNumberStyle}
          >{`${version} ${month} ${day} ${year}`}</div>
        </NavLink>
        <Navbar drawerToggleClickHandler={drawerToggleClickHandler} />
        <SideDrawer click={drawerToggleClickHandler} show={sideDrawerOpen} />
        {backdrop}
        <div className="nav-bar-offset" css={{ paddingTop: '75px' }} />
        <Routes>
          {routes.map(({ path, component: Component, ...rest }) => (
            <Route key={path} path={path} element={<Component {...rest} />} />
          ))}
          <Route element={<Unknown staticContext />} />
        </Routes>
        <Footer />
      </div>
    </I18nextProvider>
  )
}

export default App
