import { css } from '@emotion/react'

const serverPanel = css`
  position: fixed;
  display: flex;
  z-index: 3;
  flex-direction: column;
  background-color: darkgrey;
`

export { serverPanel }
