import { css } from '@emotion/react'
import { mediumScreen, smallScreen, tinyScreen } from '../styles/global'

const imageStyle = css`
  transition: all 1s ease-out;
  padding: 0.5em;
  width: 300px;
  height: 55px;
  ${mediumScreen} {
    width: 200px;
    height: 37px;
  }
  ${smallScreen} {
    width: 150px;
    height: 28px;
  }
  ${tinyScreen} {
    width: 150px;
    height: 28px;
  }
  &:hover {
    background-color: red;
    border-radius: 5px;
  }
`

const imageLogoContainer = css`
  padding-left: 0.5em;
`

export { imageStyle, imageLogoContainer }
