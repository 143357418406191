import { css } from '@emotion/react'
import { smallScreen } from '../styles/global'

const dataGrid = css`
  ul,
  p,
  h2 {
    padding: 0;
    margin: 0;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 3fr;
  border: 1px solid white;
  grid-gap: 0.2em;
  align-items: center;

  @media (max-width: ${smallScreen}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`

const dataGridItems = css`
  padding-left: 0.5em;
  text-align: left;
`

const roadmapGrid = css`
  grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr 0.5fr 3fr;

  @media (max-width: ${smallScreen}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`

export { dataGrid, dataGridItems, roadmapGrid }
