import { css } from '@emotion/react';

export const allReviewsStyle = css`
  width: 100%;

  @media (min-width: 1350px) {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    align-items: fit-content;
  }

  @media (min-width: 2000px) {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-auto-rows: auto auto auto auto auto;
    align-items: stretch;
    align-content: center;
  }

  .review-block {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 1em;
    padding-bottom: 1em;
    width: 90%;
    border-radius: 10px;
    margin-bottom: 1em;
  }
`;
