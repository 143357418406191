import { featuredHost } from './styles'
import { fadeIn, fadeOut } from '../styles/transitions'

type FeaturedProps = {
  children: React.ReactNode
  shouldShow: boolean
}

const Featured: React.FC<FeaturedProps> = ({ children, shouldShow }) => {
  const fadeClass = shouldShow ? fadeIn : fadeOut
  const classes = [featuredHost, fadeClass]

  return (
    <fieldset css={classes} className="featured-host">
      <legend>{shouldShow ? 'Featured' : ''}</legend>
      {children}
    </fieldset>
  )
}

export default Featured
