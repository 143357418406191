import { useCallback } from 'react'
import ReactGA from 'react-ga4'

const useAffiliateClick = () => {
  const initializeGA = useCallback(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize('G-24QY1PP4XK') // Replace with your actual GA tracking ID
      window.GA_INITIALIZED = true
    }
  }, [])

  const handleAffiliateClick = useCallback(
    (
      serverName: string,
      url: string,
      affiliateLogic: string,
      // aOrB: boolean,
      currentGame = 'dedi',
    ) => {
      initializeGA()

      if (process.env.NODE_ENV !== 'production') {
        console.info('Analytics affiliate event', {
          page: location.pathname,
          serverName,
          url,
          currentGame,
        })
      }

      ReactGA.event({
        category: 'Affiliate',
        action: `Click ${serverName} - ${currentGame}`,
        label: `${serverName} - ${currentGame}`, // Example of an additional data point you could track
      })

      const urlToOpen = new URL(affiliateLogic)
      window.open(urlToOpen.toString())
    },
    [initializeGA],
  )

  return handleAffiliateClick
}

export default useAffiliateClick
