import { css } from '@emotion/react'
import { largeScreen, smallScreen, tinyScreen } from '../global'

export const supportLogosStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  ${smallScreen} {
    display: none;
  }
`

export const extraServicesStyle = css`
  margin-left: 1em;
`

export const extraServicesBarStyle = css`
  width: 30%;
  float: left;
  justify-content: center;
  text-align: center;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin: 0;
  background-color: #3a832d;
  min-height: 2em;
  height: auto;
`

export const supportingBarStyle = css`
  background-color: #202020;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 2em;
  height: auto;
  color: white;
  border-radius: 0 0 5px 5px;
  ${smallScreen} {
    margin-left: 1em;
    margin-right: 1em;
  }
`

export const linkButtons = css`
  grid-area: link;
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  ${smallScreen} {
    justify-content: space-between;
    width: -webkit-fill-available;
    flex-direction: row;
  }
  ${tinyScreen} {
    width: 100%;
    flex-direction: row;
  }
`

export const hostLink = css`
  cursor: pointer;
  display: block;
  background-color: rgb(36 120 73 / 26%);
  font-size: 1em;
  padding: 0.5em;
  color: white;
  text-decoration: none;
  margin-bottom: 0.1em;
  transition: background-color 1s ease;
  &:hover {
    background-color: red;
  }
  border-radius: 3px;
`

export const singleServerListStyle = css`
  border-radius: 5px;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  background-color: #151446;
  ${smallScreen} {
    width: 90%;
  }
`

export const singleServerBlock = css`
  padding: 0.5em;
  margin: 0.2em auto;
  font-weight: bolder;
  justify-items: center;
  text-align: center;
  overflow: hidden;
  .locations,
  .control-panel,
  .platforms,
  .established,
  .trustpilot-area,
  .image-logos,
  .main-image-logo,
  .link-buttons,
  .support {
    flex-direction: column;
    align-self: center;
    color: white;
    font-weight: bolder;
  }
  grid-template-areas: 'mainImageLogo trustpilotArea locations platforms controlPanel support established link';
  grid-template-columns: 3fr 2fr 2fr 1fr 1fr 0.85fr 1fr 2fr;
  grid-template-rows: 1fr;
  display: grid;
  align-items: start;
  grid-gap: 1em;
  justify-content: center;
  ${smallScreen} {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  ${tinyScreen} {
    flex-direction: column;
    width: 90%;
  }
`

export const singleServerDediListStyle = css`
  ${largeScreen} {
    border-radius: 5px;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    background-color: #151446;
  }
`

export const singleServerDediBlock = css`
  .link-buttons {
    overflow: hidden;
  }
  font-weight: bolder;
  justify-items: center;
  text-align: center;
  overflow: hidden;
  .locations,
  .connection-speed,
  .data-limit,
  .memory-group,
  .trustpilot-area,
  .dedi-cpus,
  .dedi-os,
  .established,
  .image-logos,
  .main-image-logo,
  .link-buttons,
  .dedi-hdds,
  .ip-group,
  .price-icon,
  .connection-speed,
  .support {
    flex-direction: column;
    align-self: center;
    color: white;
    font-weight: bolder;
  }
  .price-icon {
    color: yellow;
    font-size: 1.5em;
  }
  ${tinyScreen} {
    display: block;
  }
  margin: 1em 1em 0 1em;
  display: grid;
  align-items: start;
  grid-template-columns: 1% 1fr 1fr 1fr 1%;
  grid-template-rows: auto;
  grid-gap: 1em;
  grid-template-areas: '. mainImageLogo mainImageLogo mainImageLogo . . trustpilotArea trustpilotArea support . . memoryGroup memoryGroup priceIcon . . established . locations . . dataLimit . connectionSpeed . . dediCpus dediHdds ipGroup . . link link link .';
  justify-content: center;
  .established {
    flex-direction: row;
  }
  ${smallScreen} {
    background: #1e1e1e;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  ${largeScreen} {
    grid-template-rows: 1fr;
    padding-top: 0.5em;
    grid-template-columns: 3fr 2fr 2fr 1fr 1fr 0.85fr 1fr 2fr;
    font-size: 0.65vw;
    grid-gap: 0.2em;
    grid-template-areas: 'mainImageLogo trustpilotArea priceIcon locations memoryGroup dediCpus dediHdds  ipGroup support established link';
  }
`

export const countryFlagsStyle = css`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 40%;
  width: 70%;
  ${smallScreen} {
    display: none;
  }
  ${tinyScreen} {
    margin: 0 auto;
  }
`

export const linkStyle = css`
  text-decoration: none;
  color: white;
`

export const hoverTip = css`
  cursor: context-menu;
`

export const pStyle = css`
  border: 0;
  margin: 0;
`

export const trustPStyle = css`
  border: 0;
  margin: 0;
  font-size: 2em;
`

type ModifyColorType = {
  [key: string]: string
}

export const modifyColor: ModifyColorType = {
  y2002: '#00ff00',
  y2003: '#00ff00',
  y2004: '#00ff00',
  y2005: '#00ff00',
  y2006: '#00ff00',
  y2007: '#11ee00',
  y2008: '#22dd00',
  y2009: '#33cc00',
  y2010: '#44bb00',
  y2011: '#55aa00',
  y2012: '#669900',
  y2013: '#778800',
  y2014: '#887700',
  y2015: '#996600',
  y2016: '#aa5500',
  y2017: '#bb4400',
  y2018: '#cc3300',
  y2019: '#dd2200',
  y2020: '#ee1100',
  y2021: '#ff0000',
}

export const imageStyle = (established: string) => css`
  object-fit: cover;
  width: 75%;
  color: ${modifyColor[established]};
`

export const trustPilotAreaContent = css`
  display: grid;
  justify-content: center;
  align-content: space-between;
`
