/** @jsxImportSource @emotion/react */
import { reviewBlockStyle, style } from './styles'
import Stars from '../stars'
import { forwardRef } from 'react'
import dateFunctions from '../helpers/dates'

export type SingleReviewProps = {
  latest: boolean
  review: {
    date_published: string
    review_body: string
    review_rating: number
    author_url: string
  }
}

export const SingleReview: React.FC<SingleReviewProps> = forwardRef(
  (props, ref) => {
    const { review } = props
    const { date_published, review_body, review_rating, author_url } = review
    const formattedDate = dateFunctions.formatDate(date_published)

    return (
      <div
        ref={ref as React.RefObject<HTMLDivElement>}
        css={reviewBlockStyle(review_rating)}
        className="review-block"
      >
        {/* eslint-disable-next-line */}
        <a css={style} href={author_url}>
          <h3>{props.latest}</h3>
          <p>Published Date: {formattedDate}</p>
          <p>Review: {review_body}</p>
          <div css={{ margin: '0 auto', objectFit: 'contain' }}>
            <Stars stars={review_rating} />
          </div>
        </a>
      </div>
    )
  },
)

export default SingleReview
