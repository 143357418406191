import { SortEnum } from '..'
import { ServerDataType } from '../../host-row'
import sortByNumber from '../../helpers/sort-by-number'

type SortTheDataReturnType = {
  sortedData: ServerDataType[]
  updatedSorts: {
    ratingSort: boolean
    establishedSort: boolean
    reviewsSort: boolean
    hostSort: boolean
    CGSHSort: boolean
  }
}

export const sortTheData = (
  sortBy: SortEnum,
  serverData: ServerDataType[],
  sorts: {
    ratingSort: boolean
    establishedSort: boolean
    reviewsSort: boolean
    hostSort: boolean
    CGSHSort: boolean
  },
): SortTheDataReturnType => {
  let sortedData: ServerDataType[]
  let updatedSorts = { ...sorts }

  switch (sortBy) {
    case SortEnum.overall_rating:
      sortedData = sortByNumber(
        [...serverData],
        updatedSorts.ratingSort,
        SortEnum.overall_rating,
      )
      updatedSorts.ratingSort = !updatedSorts.ratingSort
      break
    case SortEnum.established:
      sortedData = sortByNumber(
        [...serverData],
        updatedSorts.establishedSort,
        SortEnum.established,
      )
      updatedSorts.establishedSort = !updatedSorts.establishedSort
      break
    case SortEnum.review_count:
      sortedData = sortByNumber(
        [...serverData],
        updatedSorts.reviewsSort,
        SortEnum.review_count,
      )
      updatedSorts.reviewsSort = !updatedSorts.reviewsSort
      break
    case SortEnum.hosts:
      sortedData = serverData
        .slice()
        .sort((a: ServerDataType, b: ServerDataType) =>
          !updatedSorts.hostSort
            ? a.server_name.localeCompare(b.server_name)
            : b.server_name.localeCompare(a.server_name),
        )
      updatedSorts.hostSort = !updatedSorts.hostSort
      break
    case SortEnum.cgsh:
      sortedData = serverData
        .slice()
        .sort((a: ServerDataType, b: ServerDataType) => {
          if (updatedSorts.CGSHSort) {
            return (
              parseInt(a.overall_rating) * a.review_count -
              parseInt(b.overall_rating) * b.review_count
            )
          }
          return (
            parseInt(b.overall_rating) * b.review_count -
            parseInt(a.overall_rating) * a.review_count
          )
        })
      updatedSorts.CGSHSort = !updatedSorts.CGSHSort
      break
    default:
      // Default to sorting by rating if no valid sortBy value is provided
      sortedData = sortByNumber(
        [...serverData],
        updatedSorts.ratingSort,
        'overall_rating',
      )
      updatedSorts.ratingSort = !updatedSorts.ratingSort
      break
  }

  return { sortedData, updatedSorts }
}

export default sortTheData
