import { css } from '@emotion/react'

const toggleButton = css`
  height: 24px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`

const toggleButtonLine = css`
  width: 30px;
  height: 2px;
  background: white;
`

export { toggleButton, toggleButtonLine }
