/** @jsxImportSource @emotion/react */
import PictureSet from '../helpers/picture-set'
import { imageLogosStyle, imageStyle } from './styles'

const imagePath = 'images/supportImages'

type PlatformLogoProps = {
  imageName: string
}

const PlatformLogo: React.FC<PlatformLogoProps> = (props) => {
  const { imageName } = props
  return (
    <div css={imageLogosStyle}>
      <div>
        <picture>
          <PictureSet imagePath={imagePath} imageName={imageName} />
          <img
            loading="lazy"
            key={imageName}
            css={imageStyle}
            alt={imageName}
          />
        </picture>
      </div>
    </div>
  )
}

export default PlatformLogo
