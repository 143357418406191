import { configureStore } from '@reduxjs/toolkit'
import rootReducer, { RootState } from './rootReducer'

const createStore = (preloadedState?: RootState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}

export type AppStore = ReturnType<typeof createStore>
export type AppDispatch = AppStore['dispatch']

export default createStore
