/** @jsxImportSource @emotion/react */
import PictureSet from '../helpers/picture-set'
import { rowControl, imageStyle } from './styles'
const imagePath = 'images/flags'

type FlagLogoProps = {
  locale: string
}

const FlagLogo: React.FC<FlagLogoProps> = (props) => {
  const { locale } = props

  return (
    <div className="flag-logo" css={rowControl}>
      <div>
        <picture>
          <PictureSet imagePath={imagePath} imageName={locale} />
          <img loading="lazy" key={locale} css={imageStyle} alt={locale} />
        </picture>
      </div>
    </div>
  )
}

export default FlagLogo
