/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { noMobileStyle } from '../styles/global'
import { largerThanSmall } from '../styles/global'
import DrawerToggleButton from './side-drawer/drawer-toggle-button'
import AdminPanel from './admin-panel'
import NewsPanel from './news-panel'
import ServerPanel from './server-panel'
import GuideMenu from './guide-menu'
import {
  toolbar,
  toolbarNavigation,
  toolbarLogo,
  spacer,
  toolbarNavigationItems,
  linky,
  navBarLinkStyle,
  siteLogoStyle,
} from './styles'
const backgroundColorHover = '#3e3e3e'

const updateStyle = (color: string, id: string) => {
  const el = document.querySelector(`#${id}`) as HTMLElement
  el.style.backgroundColor = color
}

const serverMenuId = 'server-menu'
const guideMenuId = 'guide-menu'
const newsMenuId = 'news-menu'
const adminMenuId = 'admin-menu'

const backgroundColorDefault = '#1f1f1f'

type NavbarProps = {
  drawerToggleClickHandler: () => void
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const { t } = useTranslation()
  const [showAdminMenu, setShowAdminMenu] = useState(false)
  const [showNewsMenu, setShowNewsMenu] = useState(false)
  const [showGuideMenu, setShowGuideMenu] = useState(false)
  const [showServerMenu, setShowServerMenu] = useState(false)

  const setAdminMenu = (show: boolean | ((prevState: boolean) => boolean)) => {
    setShowAdminMenu(show)
  }

  const setNewsMenu = (show: boolean | ((prevState: boolean) => boolean)) => {
    setShowNewsMenu(show)
  }

  const setServerMenu = (show: boolean | ((prevState: boolean) => boolean)) => {
    setShowServerMenu(show)
  }

  const setGuideMenu = (show: boolean | ((prevState: boolean) => boolean)) => {
    setShowGuideMenu(show)
  }

  let adminMenu
  let newsMenu
  let serverMenu
  let guideMenu

  if (showAdminMenu) {
    adminMenu = <AdminPanel />
  }
  if (showNewsMenu) {
    newsMenu = <NewsPanel />
  }
  if (showServerMenu) {
    serverMenu = <ServerPanel />
  }
  if (showGuideMenu) {
    guideMenu = <GuideMenu />
  }

  return (
    <header className="toolbar" css={toolbar}>
      <nav css={toolbarNavigation} className="toolbar__navigation">
        <div className="drawer-toggle-button" css={largerThanSmall}>
          <DrawerToggleButton
            drawer="drawer"
            click={props.drawerToggleClickHandler}
          />
        </div>
        <div css={toolbarLogo} className="toolbar__logo">
          <NavLink css={siteLogoStyle} to="/">
            <span css={{ color: '#606060' }}>H</span>
            <span>OST</span>
            <span css={{ color: '#606060' }}>F</span>
            <span>ILTER</span>
          </NavLink>
        </div>
        <div className="spacer" css={spacer} />
        <div
          css={[toolbarNavigationItems, noMobileStyle]}
          className="toolbar__navigation-items"
        >
          <ul>
            <li
              id={serverMenuId}
              onMouseOver={() => {
                updateStyle(backgroundColorHover, serverMenuId)
                setServerMenu(true)
              }}
              onMouseLeave={() => {
                setServerMenu(false)
                updateStyle(backgroundColorDefault, serverMenuId)
              }}
            >
              <div className="nav-bar-link" css={[navBarLinkStyle, linky]}>
                Game Servers
              </div>
              {serverMenu}
            </li>
            <li
              id={guideMenuId}
              onMouseOver={() => {
                updateStyle(backgroundColorHover, guideMenuId)
                setGuideMenu(true)
              }}
              onMouseLeave={() => {
                setGuideMenu(false)
                updateStyle(backgroundColorDefault, guideMenuId)
              }}
            >
              <div className="nav-bar-link" css={[navBarLinkStyle, linky]}>
                Setup Guides
              </div>
              {guideMenu}
            </li>
            <li css={navBarLinkStyle}>
              <NavLink to="/coupons">{t('titles.coupons')}</NavLink>
            </li>
            <li
              id={newsMenuId}
              onMouseOver={() => {
                updateStyle(backgroundColorHover, newsMenuId)
                setNewsMenu(true)
              }}
              onMouseLeave={() => {
                setNewsMenu(false)
                updateStyle(backgroundColorDefault, newsMenuId)
              }}
            >
              <div css={[navBarLinkStyle, linky]}>News</div>
              {newsMenu}
            </li>
            <li css={navBarLinkStyle}>
              <NavLink css={navBarLinkStyle} to="/system-requirements">
                {t('titles.system-requirements')}
              </NavLink>
            </li>
            <li
              id={adminMenuId}
              onMouseOver={() => {
                updateStyle(backgroundColorHover, adminMenuId)
                setAdminMenu(true)
              }}
              onMouseLeave={() => {
                setAdminMenu(false)
                updateStyle(backgroundColorDefault, adminMenuId)
              }}
            >
              <div css={[navBarLinkStyle, linky]}>Support</div>
              {adminMenu}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Navbar
