import { format } from 'date-fns'

export const formatDate = (date: string) => {
  try {
    return format(new Date(date), 'dd/MM/yyyy') // Adjust format as needed
  } catch (error) {
    console.error('Error formatting date', error)

    return 'Invalid Date'
  }
}
