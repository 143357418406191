import { css } from '@emotion/react'
import { smallScreen } from '../styles/global'

const footerLink = css`
  text-decoration: none;
  margin: 0;
  text-align: center;
  color: inherit;
  ${smallScreen} {
    // border: 1px solid white;
  }
`

const logo = css`
  width: 70%;
  margin: 0 auto;
  font-weight: bolder;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const adminButtonLink = css`
  text-decoration: none;
  border-radius: 5px;
  background-color: grey;
  margin: 1em;
  padding: 1em;
  display: flex;
  align-items: center;
  color: #111111;
  &:hover {
    background-color: darkgrey;
  }
  text-align: center;
  ${smallScreen} {
    width: 50%;
  }
`

const adminLinks2 = css`
  grid-area: column3;
  background-color: #1f1f1f;
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    padding: 0;
    margin: 0;
  }
`

const adminLinks = css`
  color: inherit;
  flex-wrap: nowrap;
  justify-content: center;
  display: flex;
  flex-direction: row;
  text-align: center;
  ${smallScreen} {
    flex-direction: column;
    align-items: center;
  }
`

const footerStyle = css`
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 0.7vw;
  background-color: #202020;
  a:link,
  a:visited {
    text-decoration: none;
  }
  a:hover {
    color: #f1f1f1;
  }
  a:active {
    text-decoration: none;
  }
  ${smallScreen} {
    font-size: 1em;
  }
`

const footerColumnContainer = css`
  color: inherit;
  box-sizing: border-box;
  padding: 1em 0;
  display: grid;
  margin: 0 auto;
  grid-gap: 4em;
  grid-template-columns: 1fr 1fr 1fr;
  width: 70%;
  text-align: left;
  grid-template-areas: "column1 column2 column3";
  a {
    text-decoration: none;
  }
  ${smallScreen} {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    width: 90%;
    grid-gap: 0;
  }
`

export {
  footerLink,
  logo,
  adminButtonLink,
  adminLinks2,
  adminLinks,
  footerStyle,
  footerColumnContainer
}
