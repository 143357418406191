/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export const couponContainer = css`
  display: flex;
  justify-content: space-around;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    width: 95%;
    margin: 0 auto;
  }
`


export const couponBar = css`
  border-radius: 0 0 5px 5px;
  @media (max-width: 768px) {
    margin: 0 1em;
  }
`

export const tpClassStyle = css`
  width: 1.5em;
  height: 1.5em;
  font-size: 1em;
  background-color: red;
  border-radius: 50%;
  color: white;
  cursor: help;
`

export const establishedStyles = css`
  position: absolute;
  top: 40%;
  left: 31%;
  transform: rotate(5deg);
  font-weight: bolder;
  font-size: 0.5vw;
  @media (max-width: 768px) {
    font-size: 2vw;
  }
  @media (max-width: 480px) {
    font-size: 8vw;
  }
`
