/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { smallScreen, mediumScreen } from '../styles/global'

export const noTextDecoration = css`
  & a,
  & a:hover,
  & a:visited,
  & a:link {
    text-decoration: none;
    color: inherit;
  }
`

export const pageContainerStyle = css`
  ${noTextDecoration};
  padding: 2em;
  background-color: #f7f9fc;
  color: #333;
`

export const pageTitleStyle = css`
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 1em;
  text-align: center;
  color: #333;
`

// export const specsContainerStyle = css`
//   margin-bottom: 2em;
//   display: flex;
//   flex-direction: column;
//   background-color: #ffffff;
//   border-radius: 10px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   overflow: hidden;
// `

// export const localContainerStyle = css`
//   padding: 1.5em;
//   border-bottom: 1px solid #e0e0e0;
//   background-color: #f0f4f8;
// `

export const aboutUsPageTextStyle = css`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.5fr; /* Ensure same grid layout */
  padding: 1em 2em; /* Added padding for spacing */
  background-color: #3f51b5; /* Accent Color */
  color: #fff;
  font-weight: bold;
  text-align: center;

  ${mediumScreen} {
    grid-template-columns: 1fr 2fr 2fr;
    text-align: left;
  }

  ${smallScreen} {
    grid-template-columns: 1fr;
    text-align: left;
    padding: 1em;
  }
`

// export const specDetailsStyle = css`
//   display: grid;
//   grid-template-columns: 1fr 1.5fr 1.5fr; /* Ensure same grid layout */
//   grid-gap: 1em;
//   padding: 1.5em 2em; /* Added padding for spacing */
//   background-color: #fff;
//   color: #333;

//   ${mediumScreen} {
//     grid-template-columns: 1fr 2fr 2fr;
//     padding: 1.5em 1em;
//   }

//   ${smallScreen} {
//     grid-template-columns: 1fr;
//     padding: 1.5em 1em;
//   }

//   p {
//     margin: 0.5em 0;
//     font-size: 0.9em;
//   }
// `

export const contentStyle = css`
  padding: 1em 2em; /* Added padding for spacing */
  text-align: center;
  background-color: #e8f0fe; /* Light Blue for contrast */
  font-size: 0.8em;
`

export const specsContainerStyle = css`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const localContainerStyle = css`
  width: 100%;
`;

export const recommendedBlockStyle = css`
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc; 
  border-radius: 8px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const minimumBlockStyle = css`
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
`;

export const specDetailsStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const noMobileStyle = css`
  display: inline-block;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const mobileOnlyStyle = css`
  display: none;

  @media (max-width: 768px) {
    display: inline-block;
  }
`;
