/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Stars from '../stars'
import ImageLogo from '../image-logo'
import SupportLogo from '../support-logo'
import PlatformLogo from '../platform-logo'
import { NavLink } from 'react-router-dom'
import FlagLogo from '../flag-logo'
import Coupons from './coupons'
import { useTranslation } from 'react-i18next'
import { fadeIn, fadeOut } from '../styles/transitions'
import useAffiliateClick from '../hooks/useAffiliateClickButton'
import {
  countryFlagsStyle,
  hostLink,
  hoverTip,
  linkButtons,
  pStyle,
  singleServerBlock,
  singleServerListStyle,
  supportLogosStyle,
  trustPStyle,
  imageStyle,
  modifyColor,
} from '../styles/hostRow'

import PictureSet from '../helpers/picture-set'
import {
  buyFromLinks,
  controlPanel,
  dateEstablished,
  establishedStyles,
  gridArea,
  mainImageLogo,
  noSmall,
  tpClassStyle,
  trustPilotArea,
  trustPilotAreaContent,
} from './styles'
import { useAppSelector } from '../../app/hooks/typedHooks'

export type GamesType = {
  affiliate_details: string
  game_name: string
  game: string
  server_name: string
  featured?: 'yes' | 'no'
}

export type ReviewType = {
  review: string
  rating: number
  server_name: string
}

export type ScoresType = {
  scores: number
  reviews: number
  server_name: string
  number_of_stars: number
  review: string
}

export type ServerDataType = {
  scores: ScoresType[]
  newGames: GamesType[]
  games: GamesType[]
  gameArray: string[]
  control_panel: string
  established: number
  locations: string
  modified_url: string
  overall_rating: string
  review_count: number
  platforms: string
  server_name: string
  server_nice_name: string
  trust_pilot_sub: string
  support: string
  url: string
  affiliate_link: string
  coupons: string
  coupon_expiry: string
  coupon_description: string
  reviews: ReviewType[]
  easyLocations: string[]
  // TODO update this and the schema to boolean
  featured?: 'yes' | 'no'
}

type HostRowProps = {
  handleHostChange: any
  limitNumbers: boolean
  server: ServerDataType
  aOrB: boolean
}

const HostRow: React.FC<HostRowProps> = (props) => {
  const { t } = useTranslation()
  const { currentGame, currentHost, currentCountry, currentPlatform } =
    useAppSelector((state) => state.currentGameHost)

  const handleAffiliateClick = useAffiliateClick()
  let { server, aOrB } = props
  const {
    games,
    control_panel,
    established,
    locations,
    modified_url,
    overall_rating,
    review_count,
    platforms,
    // reviews,
    server_name,
    server_nice_name,
    trust_pilot_sub,
    support,
    url,
    affiliate_link,
    coupons,
    coupon_expiry,
    coupon_description,
  } = server

  const hoverTip = css`
    cursor: context-menu;
  `

  const date = new Date()

  const overall_rating_number = parseFloat(overall_rating)

  const cgshScore = Math.round(overall_rating_number * review_count)
  const runningYears = date.getFullYear() + 1900 - established || 1
  const cgshAvg = Math.round(cgshScore / runningYears)
  /* extract */

  const serverLocations = server.easyLocations
  const hasLocation =
    serverLocations.includes(currentCountry) || currentCountry === 'all'
  const hasGame =
    server.gameArray.includes(currentGame) || currentGame === 'all'
  const hasPlatform =
    currentPlatform === 'all' || server.platforms.includes(currentPlatform)
  const hasHost = currentHost === 'all' || server_name === currentHost
  /* extract */

  const shouldShow = hasGame && hasLocation && hasHost && hasPlatform
  const show = shouldShow ? fadeIn : fadeOut
  // const display = !shouldShow ? { display: 'none' } : {}

  // const showOrNot = props.limitNumbers ? { display: 'none' } : {}

  let affiliateLogic

  if (currentGame === 'all') {
    affiliateLogic = affiliate_link
    //use main link
  } else {
    const gameElement = games.find((game) => {
      return game.game === currentGame
    })

    affiliateLogic = gameElement
      ? gameElement.affiliate_details
      : affiliate_link
    // use game link but fall back to main link if undefined
  }

  let opts = {}

  const hostName = server_name !== currentHost ? server_name : 'all'

  if (props.limitNumbers) return null

  return (
    <div
      css={[singleServerListStyle, show]}
      key={server_name}
      className="server-list"
    >
      <div css={singleServerBlock} className="single-server-block">
        <div
          css={mainImageLogo}
          data-value={hostName}
          onClick={props.handleHostChange}
          className="main-image-logo"
        >
          <NavLink to={`/servers/${hostName}/${currentGame}`}>
            <ImageLogo
              serverName={trust_pilot_sub}
              imageName={modified_url}
              css={hoverTip}
            />
            <span
              className="desktop-title"
              css={noSmall}
              style={{ color: 'white' }}
            >
              {server_nice_name}
            </span>
          </NavLink>
        </div>
        <div css={trustPilotArea} className="trustpilot-area">
          <a
            className="trustpilot-area-content"
            href={`https://uk.trustpilot.com/review/${trust_pilot_sub}`}
            css={trustPilotAreaContent}
          >
            <Stars stars={overall_rating_number} />
            <p className="big-p" css={trustPStyle}>
              {overall_rating_number.toFixed(1)}
            </p>
            <p css={[pStyle, noSmall]}>
              {t('titles.total-reviews')}: {review_count}
            </p>
          </a>

          <p css={[pStyle, noSmall]}>
            {t('titles.our-score')}: {cgshScore}
            <span>
              &nbsp;
              <span css={tpClassStyle}>&nbsp;?&nbsp;</span>
            </span>
          </p>
          <p css={[pStyle, noSmall]}>
            {t('titles.our-score-yearly-average')}: {cgshAvg}
            <span>
              &nbsp;
              <span css={tpClassStyle}>&nbsp;?&nbsp;</span>
            </span>
          </p>
        </div>
        <div
          css={[
            countryFlagsStyle,
            { gridArea: 'locations', alignSelf: 'center' },
          ]}
          className="country-flags"
        >
          {locations.split(',').map((locale) => {
            const objectLocale = locale.trim().split(':')
            return (
              <FlagLogo key={objectLocale[0]} locale={objectLocale[0].trim()} />
            )
          })}
        </div>
        <div css={[gridArea, noSmall]} className="platforms">
          <div className="platforms-logo">
            {platforms.split(',').map((service) => {
              return <PlatformLogo key={service} imageName={service.trim()} />
            })}
          </div>
        </div>
        <div css={[controlPanel, noSmall]} className="control-panel">
          <div className="control-panel-logo">
            {control_panel.split(',').map((panel) => {
              return <p key={panel}>{panel}</p>
            })}
          </div>
        </div>
        <div
          className="support-logo"
          css={[
            supportLogosStyle,
            {
              gridArea: 'support',
            },
          ]}
        >
          {support.length > 0 &&
            support
              .trim()
              .split(',')
              .map((support) => {
                return (
                  <SupportLogo
                    key={support.trim()}
                    imageName={support.trim()}
                  />
                )
              })}
        </div>
        <div css={[dateEstablished, noSmall]} className="established">
          <div
            css={css`
              position: relative;
              text-align: center;
              color: white;
            `}
            className="container"
          >
            <picture>
              <PictureSet
                imageName="established"
                imagePath={'images/established'}
              />
              <img
                loading="lazy"
                src={`/images/established/normal/png/established.png`}
                alt="established"
                css={imageStyle(established.toString())}
              />
            </picture>
            <div
              css={[
                establishedStyles,
                { color: `${modifyColor[established]}` },
              ]}
              className="centered"
            >
              EST. {established}
            </div>
          </div>
        </div>
        <div css={linkButtons} className="link-buttons">
          <div data-value={hostName} onClick={props.handleHostChange}>
            <NavLink
              css={hostLink}
              {...opts}
              to={`/servers/${server_name}/${currentGame}`}
            >
              {t('titles.host-details')}
            </NavLink>
          </div>
          <div css={buyFromLinks} className="buy-from-links">
            <button
              className="buy-from-links-game-button"
              css={[hostLink, { backgroundColor: 'rgb(110 93 110 / 12%)' }]}
              onClick={() =>
                handleAffiliateClick(
                  server_name,
                  location.pathname,
                  affiliateLogic,
                  currentGame,
                )
              }
            >
              {t('titles.buy-from')} {props.server.server_nice_name}
            </button>
            <button
              className="buy-from-links-host-button"
              css={hostLink}
              onClick={() =>
                handleAffiliateClick(
                  server_name,
                  location.pathname,
                  affiliate_link,
                  currentGame,
                )
              }
            >
              {t('titles.go-to')} {props.server.server_nice_name}
            </button>
          </div>
        </div>
      </div>
      <Coupons
        coupon_expiry={coupon_expiry}
        coupon_description={coupon_description}
        coupons={coupons}
      />
    </div>
  )
}

export default HostRow
