/** @jsxImportSource @emotion/react */
import useAnalytics from '../hooks/useAnalytics'
import SingleReview from '../single-review'
import { allReviewsStyle } from '../styles/reviews'

import TwitterContainer from '../twitter-container'

type AllReviewsProps = {
  reviews: any
  serverName: string
}

const AllReviews: React.FC<AllReviewsProps> = ({ reviews, serverName }) => {
  useAnalytics({
    event: 'pageview',
    page: '/all-reviews',
  })

  return (
    <div css={allReviewsStyle} className="all-reviews">
      <TwitterContainer serverName={serverName} />
      {reviews.map((review: any) => (
        <SingleReview
          key={review.date_published}
          review={review}
          latest={false}
        />
      ))}
    </div>
  )
}
export default AllReviews
