/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { adminPanel, navBarLinkStyle, open, sideDrawerStyling } from './styles'

type SideDrawerProps = {
  show: boolean
  click: () => void
}

const SideDrawer: React.FC<SideDrawerProps> = (props) => {
  const { t } = useTranslation()

  let drawerClasses = [sideDrawerStyling]
  if (props.show) {
    drawerClasses = [sideDrawerStyling, open]
  }

  return (
    <nav css={drawerClasses} className="drawer-side-panel">
      <ul css={adminPanel} onClick={props.click} className="admin-panel">
        <NavLink css={navBarLinkStyle} to="/servers/all/all">
          Game Servers
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/dedicated-gaming-servers/all">
          Dedicated Servers
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/guides/rust">
          Rust Setup Guide
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/guides/ark-survival-evolved">
          Ark Setup Guide
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/guides/7-days-to-die">
          7 Days Setup Guide
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/news/ark-news">
          Ark News
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/news/steam-news">
          Steam News
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/system-requirements">
          {t('titles.system-requirements')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/coupons">
          {t('titles.coupons')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/contact-us">
          {t('titles.contact-us')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/host-area">
          {t('titles.host-area')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/roadmap">
          {t('titles.updates')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/about-us">
          {t('titles.about-us')}
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/privacy">
          {t('titles.privacy-policy')}
        </NavLink>
      </ul>
    </nav>
  )
}

export default SideDrawer
