import { ServerDataType } from '../../host-row'

const sortByNumber = (
  data: ServerDataType[],
  sort: boolean,
  sortOnProperty: keyof ServerDataType,
): ServerDataType[] =>
  data.sort((a: ServerDataType, b: ServerDataType) =>
    !sort
      ? parseFloat(b[sortOnProperty] as unknown as string) -
        parseFloat(a[sortOnProperty] as unknown as string)
      : parseFloat(a[sortOnProperty] as unknown as string) -
        parseFloat(b[sortOnProperty] as unknown as string),
  )

export default sortByNumber
