/** @jsxImportSource @emotion/react */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { navBarLinkStyle } from '../styles'

import { css } from '@emotion/react'

const adminPanelStyling = css`
  position: fixed;
  display: flex;
  zindex: 3;
  flex-direction: column;
  background-color: darkgrey;
`

const AdminPanel = () => {
  return (
    <React.Fragment>
      <ul css={adminPanelStyling} className="admin-panel">
        <NavLink css={navBarLinkStyle} to="/news/ark-news">
          Ark News
        </NavLink>
        <NavLink css={navBarLinkStyle} to="/news/steam-news">
          Steam News
        </NavLink>
      </ul>
    </React.Fragment>
  )
}

export default AdminPanel
