import reviewColorSelector from '../helpers/review-color-selector'
import { css } from '@emotion/react'

const style = css`
  color: inherit;
  text-decoration: none;
  text-style: inherit;
`

const flexStyle = css`
  align-content: center;
`

const reviewBlockStyle = (rating: number) => css`
  margin: 0 auto;
  border-radius: 10px;
  background-color: ${reviewColorSelector(rating)};
  p {
    text-align: left;
    font-size: 1em;
    line-height: normal;
  }
`

export { style, flexStyle, reviewBlockStyle }
