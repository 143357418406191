import { css } from "@emotion/react"

export const rssContainer = css`
  display: flex;
  flex-direction: column;
`

export const rssItemsContainer = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const imageDirection = (direction: number) => css`
  float: ${direction % 2 === 0 ? 'right' : 'left'};
  height: auto;
  width: 40%;
  margin: 1em;
`;

export const rssItem = (direction: number) => css`
  justify-content: ${direction % 2 === 0 ? 'flex-start' : 'flex-end'};
  max-width: 400px;
  width: 80%;
  &:hover {
    background-color: darkgrey;
  }
  cursor: pointer;
  flex-grow: 0;
  text-overflow: ellipsis;
  a,
  a:visited,
  a:link {
    text-decoration: none;
    color: white;
  }
  margin: 5px;
  box-sizing: border-box;
  padding: 1em;
  border-radius: 10px;
  background-color: grey;
  text-decoration: none;
`

export const readMoreLink = css`
  &:link,
  &:visited {
    color: red;
  }
  &:hover {
    color: orange;
  }
  color: white;
  padding: 0.5em;
  align-self: flex-end;
  border-radius: 5px;
  background-color: #1f1f1f;
  margin-top: 1rem;
`

export const rssContent = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
`

export const rssLink = css`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
`

