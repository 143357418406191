import { css } from '@emotion/react'

const buttonStyle = css`
  border: none,
  background: none,
  padding: 0,
  cursor: pointer,
`

export { buttonStyle }
