import { DediServerDataType } from '../../host-row/dedicated'

const sortByNumberDedi = (
  data: DediServerDataType[],
  sort: boolean,
  sortOnProperty: keyof DediServerDataType,
): DediServerDataType[] =>
  data.sort((a: DediServerDataType, b: DediServerDataType) =>
    !sort
      ? parseFloat(b[sortOnProperty] as unknown as string) -
        parseFloat(a[sortOnProperty] as unknown as string)
      : parseFloat(a[sortOnProperty] as unknown as string) -
        parseFloat(b[sortOnProperty] as unknown as string),
  )

export default sortByNumberDedi
