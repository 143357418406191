import { css } from '@emotion/react'
import { smallScreen, tinyScreen } from '../../styles/global'

const onlySmall = css`
  display: none;
  ${smallScreen} {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  ${tinyScreen} {
    width: 90%;
  }
`

export { onlySmall }
