/** @jsxImportSource @emotion/react */
import React from 'react'

const largeScreen = '4000px'

type PictureSetProps = {
  imagePath: string
  imageName: string
}

const PictureSet: React.FC<PictureSetProps> = (props) => {
  const { imagePath, imageName } = props
  return (
    <React.Fragment>
      <source
        media={`(max-width: ${largeScreen})`}
        type="image/avif"
        srcSet={`/${imagePath}/normal/avif/${imageName}.avif`}
      />
      <source
        media={`(max-width: ${largeScreen})`}
        type="image/webp"
        srcSet={`/${imagePath}/normal/webp/${imageName}.webp`}
      />
      <source
        media={`(max-width: ${largeScreen})`}
        type="image/png"
        srcSet={`/${imagePath}/normal/png/${imageName}.png`}
      />
    </React.Fragment>
  )
}

export default PictureSet
