import path from 'path'
// process.env.BASE_URL are replaced by webpack.DefinePlugin during compile time

export async function fetchData() {
  const urlPath = new URL(
    path.join('api', 'data'),
    process.env.BASE_URL,
  ).toString()
  const encodedURI = encodeURI(urlPath)

  try {
    const data = await fetch(encodedURI)
    const jsonData = await data.json()
    return jsonData
  } catch (error) {
    console.warn(error)
    return null
  }
}

export async function fetchCoupons() {
  const urlPath = new URL(
    path.join('api', 'coupons'),
    process.env.BASE_URL,
  ).toString()
  const encodedURI = encodeURI(urlPath)

  try {
    const data = await fetch(encodedURI)
    return await data.json()
  } catch (error) {
    console.warn(error)
    return null
  }
}

export async function fetchSpecs() {
  const urlPath = new URL(
    path.join('api', 'specs'),
    process.env.BASE_URL,
  ).toString()
  const encodedURI = encodeURI(urlPath)

  try {
    const data = await fetch(encodedURI)

    return await data.json()
  } catch (error) {
    console.warn(error)
    return null
  }
}

export async function fetchDedicated() {
  const urlPath = new URL(
    path.join('api', 'dedicated'),
    process.env.BASE_URL,
  ).toString()
  const encodedURI = encodeURI(urlPath)

  try {
    const data = await fetch(encodedURI)
    return await data.json()
  } catch (error) {
    console.warn(error)
    return null
  }
}

export default fetchData
