/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import PictureSet from '../helpers/picture-set'

const spinnyThingSetup = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
`

const spinnyThing = css`
  animation: ${spinnyThingSetup} 1s linear infinite;
`

const Loading = () => {
  return (
    <div
      className="loading-icon"
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
      }}
    >
      <p id="nope-not-here"></p>
      <PictureSet imageName="loading" imagePath="images/loading" />
      <img
        loading="lazy"
        src={'/images/loading/small/png/loading.png'}
        alt="Loading oscillating logo"
        className="load-spinner"
        css={[spinnyThing, { textAlign: 'center' }]}
      />
    </div>
  )
}

export default Loading
