import ArkSurvivalEvolved from './ark-survival-evolved'
import Rust from './rust'
import SevenDaysToDie from './7-days-to-die'
import { gameGuideStyle } from './styles'

type GameGuide = {
  [key: string]: React.FC
}

const gameGuide: GameGuide = {
  '7-days-to-die': SevenDaysToDie,
  'ark-survival-evolved': ArkSurvivalEvolved,
  rust: Rust,
}

type GuideHtmlProps = {
  game: string
}

const GuideHtml: React.FC<GuideHtmlProps> = (props) => {
  const { game } = props
  const CurrentGame = gameGuide[game]
  return (
    <div id={game} css={gameGuideStyle}>
      <a
        style={{
          backgroundColor: 'grey',
          padding: '0.5em',
          borderRadius: '5px',
        }}
        href={`/servers/all/${game}`}
      >
        Back to {game}
      </a>
      <CurrentGame />
    </div>
  )
}

export default GuideHtml
