/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { couponBar, couponContainer } from './styles'

type CouponsProps = {
  coupon_expiry: string
  coupon_description: string
  coupons: string
}

const Coupons: React.FC<CouponsProps> = ({
  coupon_expiry,
  coupon_description,
  coupons,
}) => {
  const { t } = useTranslation()
  const expiryDate = new Date(coupon_expiry.split('T')[0])
  const isValid = expiryDate > new Date()

  return isValid ? (
    <div className="coupon-box" css={couponBar}>
      <div className="coupon-container" css={couponContainer}>
        <span>{coupon_description}</span>
        <span>{coupons}</span>
      </div>
    </div>
  ) : null
}

export default Coupons
