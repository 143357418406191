/** @jsxImportSource @emotion/react */
import { backDropStyling } from './styles'

type BackDropProps = {
  click: () => void
}

const BackDrop: React.FC<BackDropProps> = (props) => {
  return (
    <div
      className="backdrop"
      role="presentation"
      css={backDropStyling}
      onClick={props.click}
    />
  )
}

export default BackDrop
