import { css } from '@emotion/react'

export const fadeOut = css`
  opacity: 0;
  transform: scaleY(0);
  max-height: 0px;
  overflow: hidden;
  transform-origin: top;
  transition: all 1s ease;
`

export const fadeIn = css`
  opacity: 1;
  max-height: 2000px;
  transform: scaleY(1);
  transform-origin: top;
  transition: all 1s ease;
`
