import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CurrentGameHostState = {
  currentGame: string
  currentHost: string
  currentPlatform: string
  currentCountry: string
}

export const currentGameHostInitialState: CurrentGameHostState = {
  currentGame: 'all',
  currentHost: 'all',
  currentPlatform: 'all',
  currentCountry: 'all',
}

const currentGameHostSlice = createSlice({
  name: 'currentGameHost',
  initialState: currentGameHostInitialState,
  reducers: {
    setCurrentGame: (state, action: PayloadAction<string>) => {
      state.currentGame = action.payload
    },
    setCurrentHost: (state, action: PayloadAction<string>) => {
      state.currentHost = action.payload
    },
    setCurrentPlatform: (state, action: PayloadAction<string>) => {
      state.currentPlatform = action.payload
    },
    setCurrentCountry: (state, action: PayloadAction<string>) => {
      state.currentCountry = action.payload
    },
  },
})

export const {
  setCurrentGame,
  setCurrentHost,
  setCurrentPlatform,
  setCurrentCountry,
} = currentGameHostSlice.actions

export default currentGameHostSlice.reducer
