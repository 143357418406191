/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import UseMetaTags from '../meta-tags'
import { pageText, pageContainer, pageTitle } from '../styles/admin'
import useAnalytics from '../hooks/useAnalytics'

const ContactUs = () => {
  useAnalytics({
    event: 'page_view',
    page_path: '/contact-us',
    page_title: 'Contact us page',
  })

  const { t } = useTranslation()
  return (
    <React.Fragment>
      <UseMetaTags
        type="website"
        ogDescription="Contact details for Compare Game Server Hosting.com."
        title="Contact us for information page"
        content="Contact details for Compare Game Server Hosting.com."
        ogTitle="Contact Page"
        ogImageUrl="https://comparegameserverhosting.com/images/ogImages/logos/normal/hosts/all.png"
        ogUrl="https://comparegameserverhosting.com/contact-us"
      />

      <div css={pageContainer} className="contact-us-page-container">
        <h1 css={pageTitle} className="contact-us-page-title">
          {t('introductions.contact-us-title')}
        </h1>
        <div css={pageText} className="contact-us-page-text">
          <p>
            {t('introductions.contact-us-text')}
            <a href="mailto:admin@comparegameserverhosting.com">HostFilter</a>
          </p>
          <p>
            <a href="mailto:admin@comparegameserverhosting.com">
              admin@comparegameserverhosting.com
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ContactUs
