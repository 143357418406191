import Grid from './grid'
import ContactUs from './contactUs'
import AboutUs from './about-us'
import Roadmap from './road-map'
import Privacy from './privacy'
import HostArea from './host-area'
import Home from './home'
import Feature from './feature'
import GamingRigs from './system-requirements'
import Coupons from './coupons'
import Dedicated from './dedicated'
import { fetchCoupons, fetchData, fetchSpecs, fetchDedicated } from './api'
import rssFeedArk from './feeds/rss-feed-ark'
import rssFeedSteam from './feeds/rss-feed-steam'
import GuideHtml from './guide-html'
import { RootState } from '../app/rootReducer'
import { CacheKeys } from '../server/cachekeys'
import Unknown from './unknown'

export type RouteType = {
  path: string
  component: any
  fetchInitialData?: (path: string) => Promise<Partial<RootState>>
  cacheKey: string
  props?: {
    [key: string]: (path: string) => string
  }
  game?: string
  host?: string
}

const routes: RouteType[] = [
  {
    path: '/',
    component: Home,
    cacheKey: CacheKeys.HOME,
  },
  {
    path: '/feature',
    component: Feature,
    cacheKey: CacheKeys.FEATURE,
  },
  {
    path: '/guides/ark-survival-evolved',
    component: GuideHtml,
    game: 'ark-survival-evolved',
    cacheKey: CacheKeys.GUIDE_ASE,
  },
  {
    path: '/guides/7-days-to-die',
    component: GuideHtml,
    game: '7-days-to-die',
    cacheKey: CacheKeys.GUIDE_7DTD,
  },
  {
    path: '/guides/rust',
    component: GuideHtml,
    game: 'rust',
    cacheKey: CacheKeys.GUIDE_RUST,
  },
  {
    path: '/news/ark-news',
    component: rssFeedArk,
    cacheKey: CacheKeys.NEWS_ARK
  },
  {
    path: '/news/steam-news',
    component: rssFeedSteam,
    cacheKey: CacheKeys.NEWS_STEAM,
  },
  {
    path: '/servers/:invalid',

    cacheKey: CacheKeys.UNKNOWN,
    component: Unknown,
    fetchInitialData: async () => {
      const fetchedData = fetchData()
      return fetchedData
    },
  },
  {
    path: '/servers/:host/:game',
    props: {
      game: (path = '') => {
        return path.split('/')[3] || ''
      },
      host: (path = '') => path.split('/')[2] || '',
    },
    cacheKey: CacheKeys.SERVERS_GAMES,
    component: Grid,
    fetchInitialData: async () => {
      const fetchedData = fetchData()
      return fetchedData
    },
  },
  {
    path: '/dedicated-gaming-servers/:host',
    props: {
      host: (path = '') => path.split('/')[2],
    },
    cacheKey: CacheKeys.DEDICATED,
    component: Dedicated,
    fetchInitialData: () => fetchDedicated(),
  },
  {
    path: '/about-us',
    component: AboutUs,
    cacheKey: CacheKeys.ABOUT_US,
  },
  {
    path: '/system-requirements',
    component: GamingRigs,
    cacheKey: CacheKeys.SYSTEM_REQUIREMENTS,
    fetchInitialData: async () => {
      const fetchedSpecData = await fetchSpecs()

      return fetchedSpecData
    },
  },
  {
    path: '/system-requirements/:game',
    component: GamingRigs,
    cacheKey: CacheKeys.SYSTEM_REQUIREMENTS,
    fetchInitialData: async () => {
      const fetchedSpecData = await fetchSpecs()

      return fetchedSpecData
    },
  },
  {
    path: '/coupons',
    component: Coupons,
    cacheKey: CacheKeys.COUPONS,
    fetchInitialData: () => fetchCoupons(),
  },
  {
    path: '/contact-us',
    component: ContactUs,
    cacheKey: CacheKeys.CONTACT_US,
  },
  {
    path: '/host-area',
    component: HostArea,
    cacheKey: CacheKeys.HOST_AREA,
  },
  {
    path: '/roadmap',
    component: Roadmap,
    cacheKey: CacheKeys.ROADMAP,
  },
  {
    path: '/privacy',
    component: Privacy,
    cacheKey: CacheKeys.PRIVACY,
  },
  {
    path: '/unknown',
    component: Unknown,
    cacheKey: CacheKeys.UNKNOWN,
  },
  {
    path: '*',
    component: Unknown,
    cacheKey: CacheKeys.UNKNOWN,
  },
]

export default routes
