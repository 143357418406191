/** @jsxImportSource @emotion/react */
import React from 'react'
import useAffiliateClick from '../../hooks/useAffiliateClickButton'
import PictureSet from '../../helpers/picture-set'
import { SerializedStyles } from '@emotion/react'
import { buttonStyle } from './styles'

type AffiliateImageButtonProps = {
  serverName: string
  url: string
  affiliateLogic: string
  aOrB: boolean
  currentGame?: string
  imagePath: string
  imageName: string
  altText: string
  loading?: 'lazy' | 'eager' | undefined
  passedStyles?: SerializedStyles
}

const AffiliateImageButton: React.FC<AffiliateImageButtonProps> = ({
  serverName,
  url,
  affiliateLogic,
  // aOrB,
  currentGame = 'dedi',
  imagePath,
  imageName,
  passedStyles,
  altText,
  loading = 'eager',
}) => {
  const handleAffiliateClick = useAffiliateClick()

  return (
    <button
      css={[buttonStyle, passedStyles]}
      onClick={() =>
        handleAffiliateClick(serverName, url, affiliateLogic, currentGame)
      }
    >
      <picture>
        <PictureSet imagePath={imagePath} imageName={imageName} />
        <img
          src={`/${imagePath}/normal/png/${imageName}.png`}
          alt={altText}
          css={{ width: '100%', height: 'auto' }}
          loading={loading}
        />
      </picture>
    </button>
  )
}

export default AffiliateImageButton
