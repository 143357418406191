/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { pageColumnText, pageContainer, pageTitle } from '../styles/admin'
import useAnalytics from '../hooks/useAnalytics'

const AboutUs = () => {
  const { t } = useTranslation()

  useAnalytics({
    event: 'pageview',
    page: '/about-us',
  })

  return (
    <div css={pageContainer} className="about-us-page-container">
      <h1 css={pageTitle} className="about-us-page-title">
        {t('introductions.about-us-title')}
      </h1>
      <div css={pageColumnText} className="about-us-page-text">
        <p>{t('introductions.about-us-text')}</p>
      </div>
    </div>
  )
}

export default AboutUs
