/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import {
  introductionContainer,
  introductionText,
  hideDescriptionButton,
} from './styles'
import { useAppSelector } from '../../app/hooks/typedHooks'

type IntroductionProps = {
  show: boolean
  toggleShowIntro: () => void
}

const Introduction: React.FC<IntroductionProps> = (props) => {
  const { currentGame, currentHost } = useAppSelector(
    (state) => state.currentGameHost,
  )
  const { t } = useTranslation()
  const backgroundImage = {
    backgroundImage:
      currentGame !== 'all' ? `url("/games/${currentGame}.jpg")` : undefined,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }
  let intro =
    currentGame === 'all'
      ? t('introductions.about-us-text')
      : t(`guides.description.${currentGame}`)

  const introArray = intro.split('\n')
  const plist = introArray.map((p, index) => <p key={index}>{p}</p>)

  const displaying = props.show
    ? { maxHeight: '3000px', transition: 'all 0.5s ease-in' }
    : { transition: 'all 0.5s ease-in', maxHeight: '0px', overflow: 'hidden' }

  if (!intro || currentHost !== 'all') return null
  return (
    <section
      style={backgroundImage}
      css={introductionContainer}
      className="introduction"
    >
      <button
        css={hideDescriptionButton}
        style={{ borderRadius: '4em' }}
        color="primary"
        onClick={props.toggleShowIntro}
      >
        {props.show ? 'Hide Description' : 'Show Description'}
      </button>

      <div css={displaying} className="introduction-container">
        <div className="introduction-text" css={introductionText}>
          {plist}
        </div>
        {/* <div className="introduction-images">
          <div className="introduction-image">An image</div>
          <div>
            Add some more text to add some extra flwo to make it look nice and
            such Make it eye catching you know
          </div>
          <div className="introduction-image">An image</div>
        </div> */}
      </div>
    </section>
  )
}

export default Introduction
