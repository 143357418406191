import { css } from '@emotion/react'
import { smallScreen } from '../../styles/global'

const couponBar = css`
  border-radius: 0 0 5px 5px;
  color: white;
  ${smallScreen}: {
    margin: 0 1em;
  }
`

const couponContainer = css`
  display: flex;
  justify-content: space-around;
  color: white;
  align-items: center;
  width: 100%;
  height: 2em;
  background-color: #161183;
  ${smallScreen}: {
    height: auto;
    flexd-irection: column;
    text-align: center;
    width: 95%;
    margin: 0 auto;
  }
`

export { couponBar, couponContainer }
