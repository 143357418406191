/** @jsxImportSource @emotion/react */
import React from 'react'
import AllReviews from '../all-reviews'
import GameList from '../game-list'
import MetaTags from '../meta-tags'
import { monthYear } from '../month-year'
import { useTranslation } from 'react-i18next'
import { ServerDataType } from '../host-row'
import { isEmpty } from 'lodash'
import { serverPageContainerStyle } from './styles'
import { useAppSelector } from '../../app/hooks/typedHooks'

export type ServerPageProps = {
  serverData: ServerDataType | null | undefined
  aOrB: boolean
  handleHostChange: any
  sortByReviews: any
}

export const ServerPage: React.FC<ServerPageProps> = (props) => {
  if (!props?.serverData) {
    return null
  }
  const { t } = useTranslation()

  const { currentGame, currentHost } = useAppSelector(
    (state) => state.currentGameHost,
  )

  const { serverData, aOrB } = props
  if (isEmpty(serverData)) return null
  const { games, trust_pilot_sub, server_name, reviews } = serverData

  const date = monthYear(new Date())
  return (
    <section css={serverPageContainerStyle} className="server-page-container">
      <MetaTags
        title={t('meta.server.title', {
          host: server_name,
          monthYear: date,
        })}
        content={t('meta.server.description', {
          host: server_name,
          monthYear: date,
        })}
        ogDescription={`${currentGame} game server offerings from ${currentHost} ${date}`}
        ogTitle={`${currentHost} game server offerings ${date}`}
        type="website"
        ogUrl={`https://comparegameserverhosting.com/servers/${currentHost}/${currentGame}`}
        ogImageUrl={`https://comparegameserverhosting.com/images/ogImages/logos/normal/${trust_pilot_sub}.png`}
      />
      <GameList games={games} server={serverData} aOrB={aOrB} />
      <h2>Latest review(s)</h2>
      <AllReviews serverName={server_name} reviews={reviews} />
    </section>
  )
}

export default ServerPage
