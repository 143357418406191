/** @jsxImportSource @emotion/react */
import React from 'react'
import { pageContainer, pageTitle } from '../styles/admin'
import { updateData, roadmapData } from './updateData'
import UseMetaTags from '../meta-tags'
import { monthYear } from '../month-year'
import { mobileOnlyStyle } from '../styles/global'
import { noMobileStyle } from '../styles/global'
import { dataGrid, dataGridItems, roadmapGrid } from './styles'

const date = monthYear(new Date())

export const RoadMap = () => {
  return (
    <React.Fragment>
      <UseMetaTags
        title="Site updates and road map for comparegameerverhosting.com"
        content="Would you like to see a list of updates and site improvements for compare game server hosting. If so please look here."
        ogTitle={`Compare games servers Roadmap ${date}`}
        ogDescription={`HostFilter roadmap ${date}`}
        type="website"
        ogUrl="https://comparegamerserverhosting/coupons"
        ogImageUrl="https://comparegameserverhosting/images/ogImages/logos/normal/all.png"
      />
      <div css={pageContainer} className="roadmap-page-container">
        <h1 css={{ textAlign: 'left' }}>Site Progress</h1>
        <h2 css={pageTitle} className="roadmap-page-title">
          Upcoming
        </h2>
        <div css={[dataGrid, roadmapGrid, noMobileStyle]}>
          <h2 css={dataGridItems}>Item</h2>
          <p css={dataGridItems}>UI</p>
          <p css={dataGridItems}>UX</p>
          <p css={dataGridItems}>Bug</p>
          <p css={dataGridItems}>Feature</p>
          <p css={dataGridItems}>Description</p>
        </div>
        {roadmapData.map((data, index) => {
          return (
            <div key={index} css={[dataGrid, roadmapGrid]}>
              <h2 css={dataGridItems}>
                <span css={mobileOnlyStyle}>Improvement: </span>
                {data.name}
              </h2>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>UX: </span>
                {data.UX}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>UI: </span>
                {data.UI}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>Bug: </span>
                {data.bug}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>Feature: </span>
                {data.feature}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>Description: </span>
                {data.description.map((description, index) => (
                  <li key={index}>{description}</li>
                ))}
              </p>
            </div>
          )
        })}
        <h2 css={pageTitle} className="siteupdates-page-title">
          Site improvements
        </h2>
        <div css={[dataGrid, noMobileStyle]}>
          <h2 css={dataGridItems}>Version</h2>
          <p css={dataGridItems}>Date</p>
          <p css={dataGridItems}>UI</p>
          <p css={dataGridItems}>UX</p>
          <p css={dataGridItems}>Feature</p>
          <p css={dataGridItems}>Bug</p>
          <p css={dataGridItems}>Description</p>
        </div>
        {updateData.map((data) => {
          return (
            <div key={data.version} css={dataGrid}>
              <h2 css={dataGridItems}>
                <span css={mobileOnlyStyle}>Version: </span>
                {data.version}
              </h2>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>Date: </span>
                {`${data.day} ${data.month} ${data.year}`}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>UI: </span>
                {data.UI}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>UX: </span>
                {data.UX}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>Feature: </span>
                {data.feature}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>Bug: </span>
                {data.bug}
              </p>
              <p css={dataGridItems}>
                <span css={mobileOnlyStyle}>Description: </span>
                {data.description.map((description, index) => (
                  <li key={index}>{description}</li>
                ))}
              </p>
            </div>
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default RoadMap
