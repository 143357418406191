/** @jsxImportSource @emotion/react */
import React from 'react'
import platformArray from '../helpers/plaformArray'
import PictureSet from '../helpers/picture-set'
import {
  displayGrid,
  currentPlatformClass,
  otherPlatformClass,
  allPlatformClass,
} from './styles'

const imagePath = 'images/platforms'

type PlatformBannerProps = {
  handlePlatformChange: any
  currentPlatform: string
}

const PlatformBanner: React.FC<PlatformBannerProps> = ({
  handlePlatformChange,
  currentPlatform,
}) => {
  return (
    <div
      id="banner-anchor"
      className="banner-images"
      css={displayGrid(platformArray.length)}
    >
      {platformArray.map((platform) => {
        const isCurrentPlatform = platform === currentPlatform
        const isAll = currentPlatform === 'all'

        const imgClass = isAll
          ? allPlatformClass
          : isCurrentPlatform
            ? currentPlatformClass
            : otherPlatformClass

        return (
          <div
            onClick={handlePlatformChange}
            data-platform={platform}
            key={platform}
            className="platform-image-container"
          >
            <div>
              <picture>
                <PictureSet imagePath={imagePath} imageName={platform} />
                <img
                  loading="lazy"
                  className={imgClass}
                  key={platform}
                  alt={`Platform icon ${platform}`}
                />
              </picture>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PlatformBanner
