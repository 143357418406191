import React from 'react'

const screen = '800px'

type PictureSetSmallProps = {
  imagePath: string
  imageName: string
}

const PictureSetSmall: React.FC<PictureSetSmallProps> = (props) => {
  const { imagePath, imageName } = props
  return (
    <React.Fragment>
      <source
        media={`(max-width: ${screen})`}
        type="image/avif"
        srcSet={`/${imagePath}/small/avif/${imageName}.avif`}
      />
      <source
        media={`(max-width: ${screen})`}
        type="image/webp"
        srcSet={`/${imagePath}/small/webp/${imageName}.webp`}
      />
      <source
        media={`(max-width: ${screen})`}
        type="image/png"
        srcSet={`/${imagePath}/small/png/${imageName}.png`}
      />
    </React.Fragment>
  )
}

export default PictureSetSmall
