/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import {
  tooltipStyle,
  tooltipVisibleStyle,
  tooltipWrapperStyle,
} from './styles'

type TooltipHolderProps = {
  title: React.ReactNode
  children: React.ReactNode
}

const TooltipHolder: React.FC<TooltipHolderProps> = ({ title, children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <div
      css={tooltipWrapperStyle}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      <div css={[tooltipStyle(-50, 0), visible && tooltipVisibleStyle]}>
        {title}
      </div>
    </div>
  )
}

export default TooltipHolder
