/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback } from 'react'
import { fetchCoupons } from '../api'
import { monthYear } from '../month-year'
import Loading from '../loading'
import { mobileOnlyStyle } from '../styles/global'
import MetaTags from '../meta-tags'
import { pageContainer, pageTitle } from '../styles/admin'
import { noTextDecoration, couponContainer, couponText } from './styles'
import monthNames from './monthNames'
import useAnalytics from '../hooks/useAnalytics'
import { useLocation } from 'react-router-dom'

type Coupon = {
  server_name: string
  server_nice_name: string
  coupon_description: string
  coupon_expiry: string
  affiliate_link: string
  coupons: string
}

const Coupons = () => {
  const [loading, setLoading] = useState(true)
  const [coupons, setCoupons] = useState<Coupon[]>([])
  const currentDate = new Date()
  const location = useLocation()

  const date = monthYear(currentDate)

  useAnalytics({
    page: 'coupons',
  })

  useEffect(() => {
    setLoading(true)
    fetchCoupons().then((dataFetch) => {
      setLoading(false)
      setCoupons(dataFetch.data)
    })
  }, [location.pathname])

  const handleAnalytics = useCallback((affiliate: string, coupon: string) => {
    useAnalytics({
      category: 'Affiliate Click from coupon',
      action: 'Went to host from coupon',
      label: `${affiliate} ${coupon}`,
    })
  }, [])

  const copyText = useCallback((affiliate: string, coupon: string) => {
    navigator.clipboard
      .writeText(coupon)
      .then(() => {
        handleAnalytics(affiliate, coupon)
        window.open(affiliate, '_blank', 'noopener')
      })
      .catch(() => {
        alert('Permission to copy denied')
      })
  }, [])

  const metaContent = `Find the latest coupons for a range of game server hosts ${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`

  return loading ? (
    <>
      <MetaTags
        ogDescription="Latest coupons for game server hosts"
        ogTitle="Latest coupons vouchers for game servers"
        type="website"
        ogUrl="https://comparegamerserverhosting/coupons"
        ogImageUrl="https://comparegameserverhosting/images/ogImages/logos/normal/hosts/all.png"
        title="Want to find the latest coupons"
        content={metaContent}
        data-react-helmet="true"
      />
      <Loading />
    </>
  ) : (
    <>
      <MetaTags
        title="Want to find the latest coupons"
        content={metaContent}
        ogDescription={`Latest coupons for game server hosts ${date}`}
        ogTitle={`Latest coupons vouchers for game servers ${date}`}
        type="website"
        ogUrl="https://comparegamerserverhosting/coupons"
        ogImageUrl="https://comparegameserverhosting/images/ogImages/logos/normal/hosts/all.png"
      />
      <div
        css={[pageContainer, noTextDecoration]}
        className="coupons-page-container"
      >
        <h1 css={pageTitle} className="coupons-page-title">
          Coupons
        </h1>
        <h2 css={{ textAlign: 'center' }}>
          A list of currently available coupons.
        </h2>
        <div
          className="coupons-container"
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          {coupons
            .filter(
              (coupon) =>
                new Date(coupon.coupon_expiry.split('T')[0]) >
                new Date(Date.now()),
            )
            .map((coupon) => (
              <div
                className="coupon-container"
                css={couponContainer}
                key={coupon.server_name + coupon.coupons}
                onClick={(e) => {
                  e.preventDefault()
                  copyText(coupon.affiliate_link, coupon.coupons)
                }}
              >
                <div css={{ margin: '0.1em auto' }}>
                  <div css={couponText} className="coupon-text">
                    <p>
                      <span css={mobileOnlyStyle}>Server Name: </span>
                      {coupon.server_nice_name}
                    </p>
                    <p>
                      <span css={mobileOnlyStyle}>Description: </span>
                      {coupon.coupon_description}
                    </p>
                    <p>
                      <span css={mobileOnlyStyle}>Expiry Date: </span>
                      {coupon.coupon_expiry.split('T')[0]}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default Coupons
