/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import Select from 'react-select'
import { fetchSpecData } from '../../app/slices/gameSpecificationSlice'
import normaliseName from '../helpers/normalise-game-name'
import { pageContainerStyle, pageTitleStyle } from './styles'
import { useAppDispatch, useAppSelector } from '../../app/hooks/typedHooks'
import { useNavigate } from 'react-router'
import GameSpec from './game-spec'
import MetaTags from '../meta-tags'
import AmazonLinks from '../amazon-links'

const GamingRigs = () => {
  const { gameSpecData, loading, error } = useAppSelector((state) => {
    return state.gameSpecificationData
  })

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!Object.keys(gameSpecData)?.length) {
      console.info(
        'Trying to fetch spec data from server because gameSpecData is empty',
      )
      dispatch(fetchSpecData())
    }
  }, [dispatch, gameSpecData])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div> // Display error message
  }

  // Map gameSpecData into options for the Select component
  const gameOptions = Object.keys(gameSpecData).map((game) => ({
    value: game,
    label: normaliseName(game),
  }))

  const handleChange = (selectedOption: any) => {
    if (selectedOption) {
      const validGame = gameSpecData[selectedOption.value]

      if (validGame) {
        // Escape the selected option value and navigate to the corresponding game page
        const safeGameValue = encodeURIComponent(selectedOption.value) // Escapes the value
        navigate(`/system-requirements/${safeGameValue}`)
      } else {
        console.warn('Invalid game selected')
      }
    }
  }

  return (
    <div
      css={pageContainerStyle}
      className="system-requirements-page-container"
    >
      <MetaTags
        title="System Requirements"
        content="A list of the recommended server specifications for the different Games."
        ogTitle="System Requirements"
        type="website"
        ogUrl="https://www.comparegameserverhosting.com/system-requirements"
        ogDescription="A list of the recommended server specifications for the different Games."
        ogImageUrl={`https://www.comparegameserverhosting.com/images/ogImages/logos/normal/games/all.png`}
      />
      <AmazonLinks />
      <h1 css={pageTitleStyle} className="system-requirements">
        System Requirements
      </h1>
      <h2 css={{ textAlign: 'center' }}>
        Recomended PC specifications for playing.
      </h2>

      <Select
        options={gameOptions} // Now this contains the correct value/label pairs
        onChange={handleChange}
        placeholder="Select a game..."
        instanceId="game-select"
      />

      <GameSpec />
    </div>
  )
}

export default GamingRigs
